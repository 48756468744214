import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docsToItems, docToItem } from '../transform.docs';
import { deleteDoc, getAllDocsByRevendaId, updateDoc } from './collectionBaseWorker';

export const getNewSubscriptionById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.NEW_SUBSCRIPTIONS_CLIENTS).doc(id).get()
    return docToItem(result)
}

export const getAllSubscriptionByRevendaId = async ({ revendaId }) => {
    return await getAllDocsByRevendaId({ collection: Collection.NEW_SUBSCRIPTIONS_CLIENTS, revendaId: revendaId })
}

export const getPendingSubscriptionByStartDateRevendaId = async ({ startDate, revendaId }) => {
    const result = await firebase.firestore().collection(Collection.NEW_SUBSCRIPTIONS_CLIENTS)
        .where("changeDate", ">=", startDate)
        .get()
    const docs = docsToItems(result)
    return docs.filter(e => e.revendaId === revendaId && e.status !== "PAYED" && e.status !== "CANCELED")
}

export const updateNewSubscriptionById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.NEW_SUBSCRIPTIONS_CLIENTS, id: id, data: data })
}

export const deleteNewSubscriptionItem = async ({ id }) => {
    await deleteDoc({ collection: Collection.NEW_SUBSCRIPTIONS_CLIENTS, id: id })
}