import moment from 'moment';
import firebase from '../../config/firebase';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';
import { addDoc, deleteDoc, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';
import { Collection, WhereField } from '../../shared/constants';
import Moment from 'moment';

export const getAllNewNotesByClientId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.NEW_NOTE, clienteId: clienteId })
}

export const getNewNotesByParkId = async ({ parkId, days, status, type }) => {
    var query = firebase.firestore().collection(Collection.NEW_NOTE)
    var date = new Date();
    date.setDate(date.getDate() - days);
    query = query.where('createdAt', '>=', date).where(WhereField.PARKID, '==', parkId).orderBy("createdAt", "desc")
    if (status) {
        query = query.where('currentStatus', '==', status)
    }
    if (type) {
        query = query.where('type', '==', type)
    }
    const results = await query.get()
    return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
}

export const getNewNotesInRangeByParkId = async ({ parkId, startDate, endDate, status, type, limit }) => {
    var query = firebase.firestore().collection(Collection.NEW_NOTE)
    const newStartDate = Moment(startDate).utc().startOf('day').toDate();
    const newEndDate = Moment(endDate).utc().endOf('day').toDate();
    query = query.where('createdAt', '>=', newStartDate)
        .where('createdAt', '<=', newEndDate)
        .where(WhereField.PARKID, '==', parkId).orderBy("createdAt", "desc")
    if (status) {
        query = query.where('currentStatus', '==', status)
    }
    if (type !== "Todos") {
        query = query.where('type', '==', type)
    }
    const results = await query.limit(limit ?? 10).get()
    return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
}

export const getAllGeneratedNotesInCurrentMonth = async ({ parkId }) => {
    try {
        const startOfMonth = moment().startOf('month')
        const endOfMonth = moment().endOf('month')
        const results = await firebase.firestore().collection(Collection.NEW_NOTE)
            .where('createdAt', '>=', startOfMonth.toDate())
            .where('createdAt', '<=', endOfMonth.toDate())
            .where(WhereField.PARKID, '==', parkId).get()
        return docsToItems(results)
    } catch (error) {
    }
}

export const getAllByRessaleId = async ({ ressaleId, limit }) => {
    try {
        // .where('revendaId', '==', ressaleId)
        var results = await firebase.firestore().collection(Collection.NEW_NOTE).orderBy("createdAt", "desc").limit(limit ?? 5).get()
        return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
    } catch (error) {
        return []
    }
}

export const getAllChargeNotesByRessaleId = async ({ ressaleId }) => {
    var results = await firebase.firestore().collection(Collection.NEW_NOTE).where('revendaId', '==', ressaleId).where('type', '==', "cobranca").get()
    return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
}

export const getNewNoteById = async ({ id }) => {
    var results = await firebase.firestore().collection(Collection.NEW_NOTE).doc(id).get()
    return docToItem(results)
}

export const updateNewNoteById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.NEW_NOTE, id: id, data: data })
}

export const deleteNewNoteById = async ({ id }) => {
    await deleteDoc({ collection: Collection.NEW_NOTE, id: id })
}

export const addNewNote = async ({ data }) => {
    data.origin = "admin"
    const doc = await addDoc({ collection: Collection.NEW_NOTE, data: data })
    data.id = doc.id
    return data
}

export const getNewNoteByIdentifier = async ({ identifier }) => {
    const result = await firebase.firestore().collection(Collection.NEW_NOTE).where(WhereField.IDENTIFIER, "==", identifier).get()
    return docsToItem(result)
}