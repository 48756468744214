import firebase from '../../config/firebase';
import { addDoc, deleteDoc, getDocById, updateDoc } from './collectionBaseWorker';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems } from '../transform.docs';

export const getEventCouponById = async ({ id }) => {
    return await getDocById({ collection: Collection.EVENTS_COUPON, id: id })
}

export const getAllEventsCouponByEventAndParkId = async ({ eventId, estacionamentoId }) => {
    const docs = await firebase.firestore().collection(Collection.EVENTS_COUPON)
        .where(WhereField.EVENT_ID, "==", eventId)
        .where(WhereField.PARKID, "==", estacionamentoId)
        .get()
    return docsToItems(docs)
}

export const addEventCoupon = async ({ data }) => {
    await addDoc({ collection: Collection.EVENTS_COUPON, data: data })
}

export const updateEventCouponById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.EVENTS_COUPON, id: id, data: data })
}

export const removeEventCouponById = async ({ id }) => {
    await deleteDoc({ collection: Collection.EVENTS_COUPON, id: id })
}
