import firebase from '../../config/firebase';
import { getCliente, getEstacionamento } from '../../config/auth'
import { generateUUIDV7, isNullOrEmpty } from '../../shared/utils';
import { docToItem, docsToItem, docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, updateDoc } from './collectionBaseWorker';
import { Collection, TicketStatus, WhereField } from '../../shared/constants';

export const addTicket = async ({ data }) => {
    const doc = await addDoc({ collection: Collection.TICKET, data: data })
    return doc.id
}

export const getTicketById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).doc(id).get()
    return docToItem(result)
}

export const getRandomNumberInTicketByParkId = async ({ estacionamentoId }) => {
    const number = parseInt(Math.random() * 999999).toString().padEnd(6, "0")
    const exist = await getTicketByNumberAndParkId({ ticket: number, parkId: estacionamentoId })
    if (exist) {
        return await getRandomNumberInTicketByParkId({ estacionamentoId: estacionamentoId })
    }
    return number
}

export const ticketsByMonthlyId = async ({ monthlyId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where('monthly.id', '==', monthlyId).get()
    return docsToItems(result)
}

export const ticketsParkedByMonthlyId = async ({ monthlyId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where('monthly.id', '==', monthlyId).where('caixaFechado', '==', false).get()
    const tickets = docsToItems(result)
    return tickets.filter(e => e.status == TicketStatus.PARKING || e.status == TicketStatus.PAYED_AND_PARKING || e.status == TicketStatus.PAYED_AND_WAITING_BACK)
}

export const ticketsParkedByAccreditedId = async ({ accreditedId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where('accredited.id', '==', accreditedId).where('caixaFechado', '==', false).get()
    const tickets = docsToItems(result)
    return tickets.filter(e => e.status == TicketStatus.PARKING || e.status == TicketStatus.PAYED_AND_PARKING || e.status == TicketStatus.PAYED_AND_WAITING_BACK)
}

export const ticketsByAccreditedId = async ({ accreditedId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where('accredited.id', '==', accreditedId).get()
    return docsToItems(result)
}

export const removeTicketById = async (id) => {
    await deleteDoc({ collection: Collection.TICKET, id: id })
}

export const updateTicket = async (id, data) => {
    data.deviceIdentifier = generateUUIDV7()
    await updateDoc({ collection: Collection.TICKET, id: id, data: data })
}

export const ticketsByPlate = async ({ placa }) => {
    const parkId = getEstacionamento()?.id
    if (parkId) {
        const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.PARKID, "==", parkId).where('placa', '==', placa).get()
        return docsToItems(result)
    }
}

export const ticketsByPlateAndClientId = async ({ placa }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.CLIENTEID, "==", getCliente()?.id).where('placa', '==', placa).get()
    return docsToItems(result)
}

export const ticketsByNumberAndClientId = async ({ number, clienteId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(Collection.TICKET, '==', number).where('clienteId', '==', clienteId).get()
    return docsToItems(result)
}

export const ticketsByCameraInWithIdentifierAndParkId = async ({ identifier, estacionamentoId, limit }) => {
    const result = await firebase.firestore().collection(Collection.TICKET)
        .where(WhereField.PARKID, "==", estacionamentoId)
        .where('camera.in', '==', identifier)
        .limit(limit ?? 200)
        .get()
    return docsToItems(result)
}

export const ticketsByCameraOutWithIdentifierAndParkId = async ({ identifier, estacionamentoId, limit }) => {
    const result = await firebase.firestore().collection(Collection.TICKET)
        .where(WhereField.PARKID, "==", estacionamentoId)
        .where('camera.out', '==', identifier)
        .limit(limit ?? 200)
        .get()
    return docsToItems(result)
}

export const getTicketsByClientId = async (clienteId) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.CLIENTEID, "==", clienteId).get()
    return docsToItems(result)
}

export const getTicketByNumberAndParkId = async ({ ticket, parkId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where("ticket", "==", String(ticket)).where(WhereField.PARKID, "==", parkId).where("caixaFechado", "==", false).get()
    return docsToItem(result)
}

export const getTicketByPlateAndParkId = async ({ plate, parkId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where("placa", "==", plate.toUpperCase()).where(WhereField.PARKID, "==", parkId).where("caixaFechado", "==", false).get()
    return docsToItem(result)
}

export const getTicketByNoteIdentifier = async ({ identifier }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where("newNote.identifier", "==", identifier).get()
    return docsToItem(result)
}

export const getTicketsOpenByClientId = async ({ clienteId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.CLIENTEID, "==", clienteId).where("caixaFechado", "==", false).get()
    return docsToItems(result)
}

export const getTicketsByCashierId = async ({ estacionamentoId, caixaId }) => {
    const results = await firebase.firestore().collection("ticket")
        .where(WhereField.PARKID, "==", estacionamentoId)
        .where('caixasIds', 'array-contains-any', [caixaId])
        .get()
    return docsToItems(results)
}

export const getTicketsOpenByPlateAndClienteId = async ({ plate, clienteId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.CLIENTEID, "==", clienteId).where("placa", "==", plate.toUpperCase()).where("caixaFechado", "==", false).get()
    return docsToItem(result)
}

export const getTicketOpenByNumberAndClienteId = async ({ number, clienteId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.CLIENTEID, "==", clienteId).where("ticket", "==", number).where("caixaFechado", "==", false).get()
    return docsToItem(result)
}

export const getAllLastTickets = async ({ limit, associatedRessales }) => {
    if (isNullOrEmpty(associatedRessales)) {
        return []
    }
    const results = await firebase.firestore().collection(Collection.TICKET).where("revendaId", "in", associatedRessales.map(e => e.value)).orderBy("createAt", "desc").limit(limit).get()
    return docsToItems(results)
}

export const getAllRequestedTickets = async ({ estacionamentoId }) => {
    const results = await firebase.firestore().collection(Collection.TICKET).where(WhereField.PARKID, "==", estacionamentoId).where("requested", "==", true).get()
    return docsToItems(results)
}

export const getTicketsOpenByParkId = async (parkId) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.PARKID, "==", parkId).where("caixaFechado", "==", false).get()
    return docsToItems(result)
}

export const getAllTicketsOpenByParkId = async (parkId) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.PARKID, "==", parkId).where("status", 'in', ["Estacionado", "Pago e Estacionado", "Pago e Aguardando"]).where("caixaFechado", "==", false).get()
    return docsToItems(result)
}

export const getCountAllTicketsOpenByParkId = async (parkId) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.PARKID, "==", parkId).where("status", 'in', ["Estacionado", "Pago e Estacionado", "Pago e Aguardando"]).where("caixaFechado", "==", false).get()
    return docsToItems(result)
}

export const getAllTicketsByDatesInRange = async ({ startDate, endDate, estacionamentosIds }) => {
    const result = await firebase.firestore().collection(Collection.TICKET)
        .where('createAt', '>=', startDate)
        .where('createAt', '<=', endDate)
        .where('estacionamentoId', 'in', estacionamentosIds).get()
    return docsToItems(result).sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
}

export const getAllTicketsOpenByStatusAndParkId = async ({ status, parkId }) => {
    const result = await firebase.firestore().collection(Collection.TICKET).where(WhereField.PARKID, "==", parkId).where("caixaFechado", "==", false).get()
    return docsToItems(result).filter(e => status.indexOf(e.status) !== -1).sort((a, b) => a.createAt?.toDate() - b.createAt?.toDate())
}

export const ticketsByPlates = async ({ placas }) => {
    var tickets = []
    for (const placa of placas) {
        const result = await ticketsByPlate({ placa: placa })
        tickets.push(result)
    }
    return tickets.flatMap(e => e)
}

export const ticketsByPlatesAndClientId = async ({ placas }) => {
    var tickets = []
    for (const placa of placas) {
        const result = await ticketsByPlateAndClientId({ placa: placa })
        tickets.push(result)
    }
    return tickets.flatMap(e => e)
}

export const updateTicketById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.TICKET, id: id, data: data })
}