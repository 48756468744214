import moment from 'moment'
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuarioCompact, isAdministrador } from '../config/auth'
import { addDoc } from '../store/collections/collectionBaseWorker'
import { Collection } from './constants'

const _trackEvent = async (screen, event, type, force, json) => {
    const estacionamento = getEstacionamento()
    if (!estacionamento) {
        return
    }
    const cliente = getCliente()
    const usuario = getUsuarioCompact()
    const data = {
        type: type,
        screen: screen,
        event: event,
        estacionamentoId: estacionamento.id,
        estacionamentoNome: estacionamento.nome,
        revendaId: getRevendaIdByPark(),
        clienteId: cliente.id,
        clienteNome: cliente.nome,
        origin: 'site',
        json: json,
        user: usuario,
        isDeleted: false,
        createdAt: new Date()
    }
    await addDoc({ collection: Collection.ANALYTICS, data: data })
}

export const sendScreenView = (screen, event, force) => {
    _trackEvent(screen, event, "screenView", force)
}

export const sendClickButton = (screen, button, force, json) => {
    _trackEvent(screen, button, "click", force, json)
}

export const sendLog = (screen, event, force, json) => {
    _trackEvent(screen, event, "log", force, json)
}

export const sendError = (screen, event, force, json) => {
    _trackEvent(screen, event, "error", force, json)
}

export const sendLogByUser = async (module, message, data, internal) => {
    const estacionamento = getEstacionamento()
    if (!estacionamento) {
        return
    }
    const cliente = getCliente()
    const row = {
        type: "log",
        screen: module,
        event: message,
        estacionamentoId: estacionamento.id,
        estacionamentoNome: estacionamento.nome,
        revendaId: getRevendaIdByPark(),
        clienteId: cliente.id,
        clienteNome: cliente.nome,
        internal: internal ?? isAdministrador(),
        origin: 'site',
        json: data,
    }
    await addDoc({ collection: Collection.ANALYTICS, data: row })
}
