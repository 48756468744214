import React from 'react';
import './index.css';
import 'firebase/auth';
import { fetchRevendaByHost } from '../../../shared/service/revendaService';
import { getURLFromURI } from '../../../store/filesWorker';
import { getRevendaLinkWhatsApp } from '../../../config/auth';

class EquipamentosTutoriais extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            logoAdministrativo: ""
        }
        this.loadRevenda()
    }

    loadRevenda = async () => {
        const revenda = await fetchRevendaByHost()
        if (revenda.logoAdministrativo) {
            const logoAdministrativo = await getURLFromURI(revenda.logoAdministrativo)
            this.setState({ logoAdministrativo: logoAdministrativo })
        }
        this.setState({ revenda: revenda })
        this.setState({ linkWhatsApp: getRevendaLinkWhatsApp() })
        this.setState({ isPagginLoading: false })
    }

    render() {
        return (
            <div className='row'>
                <div className='col-lg-6'>
                    <div className="image-container my-3">
                        <img height='50' src={this.state.logoAdministrativo} />
                    </div>
                    <div className="login-content">
                        <h2 style={{ color: this.state.revenda?.siteCor }}><strong>Tutoriais</strong></h2>
                        <h6>
                            Nesta página você terá acesso a tutoriais e arquivos que não são encontrados nas lojas de apps ou POS. <p />
                        </h6>
                        <h3 style={{ color: this.state.revenda?.siteCor }}><strong>Totem de auto atendimento</strong></h3>
                        <div className='row mt-4'>
                            <h6>
                                Hoje temos integrações com os seguintes totens de auto atendimento:<br />
                                - Gertec Smart Kiosk SK210 <p />
                                Em homologação <br />
                                - POSTech <br />
                                - PagTotem ( PagSeguro )
                            </h6>
                        </div>
                        <div className='row mt-4'>
                            <h3 style={{ color: this.state.revenda?.siteCor }}><strong>Adquirentes homologadas</strong></h3> <br />
                            <h6>
                                Stone, PagSeguro, Global, UseRede, Cielo, Vero e Safra<p />
                            </h6>
                            <h3 style={{ color: this.state.revenda?.siteCor }}><strong>TEF</strong></h3> <br />
                            <h6>
                                Hoje temos integrações com as seguintes software houses:<br />
                                - Destaxa <p />
                                Em homologação <br />
                                - Sitef<p />
                            </h6>
                            <h6>
                                Equipamentos de TEF homologados<p />
                                <div className='table-responsive'>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><strong>Gertec</strong></td>
                                                <td><strong>Ingênico</strong></td>
                                                <td><strong>PAX</strong></td>
                                            </tr>
                                            <tr>
                                                <td>PPC920</td>
                                                <td>IPP320</td>
                                                <td>D180</td>
                                            </tr>
                                            <tr>
                                                <td>PPC930</td>
                                                <td>Lane 3000</td>
                                                <td>D200</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </h6>
                        </div>
                        <div className='row mt-4'>
                            <h3 style={{ color: this.state.revenda?.siteCor }}><strong>Aplicativo</strong></h3> <p />
                            <h6>
                                Para instalar o aplicativo no totem de auto atendimento é necessário acessar a Google Play baixar o aplicativo abaixo:<p />
                                <a href={this.state.revenda?.appLinkLoja} target='_blank'><strong>{this.state.revenda?.appLinkLoja}</strong></a><p />
                                Faça login com a conta do cliente.
                            </h6>
                        </div>
                        <div className='row mt-4'>
                            <h3 style={{ color: this.state.revenda?.siteCor }}><strong>Alterar modo de uso</strong></h3> <p />
                            <h6>
                                Para que o totem entre em modo de auto atendimento, é preciso fazer login em {this.state.revenda?.siteAdm} com sua conta de suporte, selecione o estabelecimento atual e siga o caminho abaixo:<p />
                                {"Equipamentos -> Aparelhos"}

                                Toque no ícone ao lado direito chamado: Modo de uso e selecione "Auto atendimento".<p />
                                Para habilitar o TEF, toque em "Habilitar TEF" no ícone lateral direito.<p />
                            </h6>
                        </div>
                        <div className='row mt-4'>
                            <h3 style={{ color: this.state.revenda?.siteCor }}><strong>Ativação Totem</strong></h3> <p />
                            <h6>
                                Para equipamentos que forem usar o TEF integrado com a software house <strong>Destaxa</strong>:<p />
                                1 - Faça login com uma conta;<br />
                                2 - Toque na engrenagem no canto esquerdo superior;<br />
                                3 - No fim da tela toque em "Ativar terminal";<br />
                                4 - Preencha com a licença e o CNPJ da empresa configurada na Destaxa;<br />
                                5 - Agora é só iniciar um fluxo de pagamento de um veículo no aplicativo;<br />
                            </h6>
                        </div>
                        <div className='row mt-4'>
                            <h3 style={{ color: this.state.revenda?.siteCor }}><strong>Ativação totem/cancela</strong></h3> <p />
                            <h6>
                                É preciso configurar o totem para que envie os dados para a plataforma.<p />
                                1 - Configure para que o totem tenha um IP fixo ou DHCP;<br />
                                2 - Defina o número do terminal para cada equipamento no painel digital;<br />
                                3 - Acesse via IP o totem com um navegador de internet o usuário e a senha padrão é <strong>admin</strong>;<br />
                                4 - Após o login, marque o campo <strong>Habilitar DNS</strong>;<br />
                                5 - Preencha o campo <strong>X_API_KEY</strong> com a chave de API do cliente, você consegue encontrar tocando <a target='_blank' href="/configuracao/parametros?tab=integracoes">aqui</a> cada cliente tem uma chave única;<br /> 
                                6 - É preciso configurar o jumper na placa para indicar se o totem é de entrada ou saída;<br />
                                7 - Acesse o menu de totens para conferir se os equipamentos estão conectados tocando <a target='_blank' href="/equipamentos/totens">aqui</a>.<br />
                            </h6>
                        </div>
                        <div className='row mt-4'>
                            <h3 style={{ color: this.state.revenda?.siteCor }}><strong>Suporte</strong></h3> <p />
                            <h6>
                                Caso tenha alguma dificuldade entre em contato no número abaixo via WhatsApp:<p />
                                <a href={this.state.linkWhatsApp} target='_blank'><strong>{this.state.revenda?.whatsAppSuporte}</strong></a><p />
                            </h6>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 pos-container' style={{ backgroundColor: this.state.revenda?.siteCor }}>
                    <div className="row">
                        <div className="promotion-content" style={{ backgroundColor: this.state.revenda?.siteCor }}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { EquipamentosTutoriais };