import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllContractsByMonthlyId = async ({ id }) => {
    const docs = await firebase.firestore().collection(Collection.MONTHLIES_CONTRACTS).where(WhereField.MONTHLY_ID, "==", id).get()
    return docsToItems(docs)
}

export const deleteContractById = async ({ id }) => {
    await deleteDoc({ collection: Collection.MONTHLIES_CONTRACTS, id: id })
}