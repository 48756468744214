import { addDoc, deleteDoc, getAllDocsByParkId, getDocById, updateDoc } from './collectionBaseWorker';
import { Collection } from '../../shared/constants';

export const getEventById = async ({ id }) => {
    return await getDocById({ collection: Collection.EVENTS, id: id })
}

export const getAllEventsByParkId = async ({ estacionamentoId }) => {
    return await getAllDocsByParkId({ collection: Collection.EVENTS, estacionamentoId: estacionamentoId })
}

export const addEvent = async ({ data }) => {
    return await addDoc({ collection: Collection.EVENTS, data: data })
}

export const updateEventById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.EVENTS, id: id, data: data })
}

export const removeEventById = async ({ id }) => {
    await deleteDoc({ collection: Collection.EVENTS, id: id })
}
