import { addUser, updateUser, getUser } from "../../store/collections/userWorker"
import { addCliente, getClient, getClientById } from "../../store/collections/clienteWorker"
import { addPark, getAllParksByClientId } from "../../store/collections/parkWorker"
import { addParameter } from "../../store/collections/parameterWorker"
import { addLicenca, getLicencas } from "../../store/collections/licencaWorker"
import { addPaymentTable } from "../../store/collections/paymentTableWorker"
import { addProfileFilled, getProfile, getProfileById } from "../../store/collections/profileWorker"
import { addPayment } from "../../store/collections/paymentWorker"
import { addProduct } from "../../store/collections/productWorker"
import { addVerify } from "../../store/collections/cellphoneVerifyWorker"
import { sendTemplateWhatsApp } from "../../store/collections/queueMailingWorker"
import { getRevendaById } from '../../store/collections/revendaWorker';
import { getRevendaByCode } from '../../shared/service/revendaService';
import { getURLFromURI } from "../../store/filesWorker"
import { isValidDocument, normalizedField } from "../../shared/utils"
import { addPrismSettings } from "../../store/collections/prismWorker"
import { addQueue } from "../../store/collections/queueWorker"

export const createAccount = async ({ nome, email, phone, park, document, password, comoConheceu, resaleCode, revendaId, welcomeEmail, isTermsUpdated }, updateStatus, error, completed) => {
    const responseValidation = validateFields({
        nome: nome,
        email: email,
        phone: phone,
        document: document,
        park: park,
        password: password,
        comoConheceu: comoConheceu
    })
    if (responseValidation.success === false) {
        error(responseValidation.message)
        return
    }
    var idRevenda = revendaId
    if (resaleCode) {
        updateStatus({ progress: 5, text: "Buscando revenda" })
        const result = await getRevendaByCode({ code: resaleCode })
        if (!result.success) {
            error(result.message)
            return
        } else {
            idRevenda = result.data.id
        }
    }

    updateStatus({ progress: 10, text: "Cadastrando usuário..." })
    const userResponse = await addUser({
        nome: nome,
        email: email,
        phone: phone,
        password: password,
        revendaId: idRevenda,
        isTermsUpdated: isTermsUpdated
    })
    if (userResponse.success === false) {
        error(userResponse.message)
        return
    }
    const user = userResponse.user
    updateStatus({ progress: 20, text: "Cadastrando cliente..." })
    const clientId = await addCliente({
        nome: park,
        representante: nome,
        email: email,
        phone: phone,
        document: document,
        revendaId: idRevenda,
        welcomeEmail: welcomeEmail
    })

    updateStatus({ progress: 30, text: "Cadastrando estabelecimento..." })
    const estacionamentoId = await addPark({
        clienteId: clientId,
        nome: park,
        representante: nome,
        email: email,
        phone: phone,
        document: document,
        revendaId: idRevenda
    })

    await addParameter({
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
        revendaId: idRevenda
    })

    updateStatus({ progress: 40, text: "Cadastrando licença..." })
    await addLicenca({
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
        revendaId: idRevenda
    })

    updateStatus({ progress: 50, text: "Salvando tabela de preço..." })
    await addPaymentTable({
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
        revendaId: idRevenda
    })

    updateStatus({ progress: 60, text: "Salvando perfil administrativo..." })
    const profileId = await addProfileFilled({
        clienteId: clientId,
        clienteNome: park,
        estacionamentoId: estacionamentoId,
        revendaId: idRevenda
    })

    updateStatus({ progress: 70, text: "Adicionando principais formas de pagamento..." })
    //Formas de pagamento
    const types = ["Débito", "Crédito", "Dinheiro", "Pix", "Cortesia"]
    for (let index = 0; index < types.length; index++) {
        const type = types[index];
        await addPayment({
            clienteId: clientId,
            estacionamentoId: estacionamentoId,
            nome: type,
            tipo: type,
            status: "Ativo",
            revendaId: idRevenda
        })
    }

    updateStatus({ progress: 80, text: "Atualizando usuário..." })
    await updateUser({
        userId: user.id,
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
        perfilId: profileId,
        status: "ACTIVE",
        parkName: park,
        revendaId: idRevenda
    })

    const products = [
        { descricao: "Ex: Lavagem simples", valor: 50.0, valorCusto: 30.0, margemLucro: 40.0, tipoProduto: "SERVICES" },
        { descricao: "Ex: Lavagem completa", valor: 100.0, valorCusto: 50.0, margemLucro: 50.0, tipoProduto: "SERVICES" },
        { descricao: "Ex: Porção de batata frita", valor: 15.0, valorCusto: 5.0, margemLucro: 66.0, tipoProduto: "SELL" }
    ]

    updateStatus({ progress: 90, text: "Finalizando cadastro..." })
    for (let index = 0; index < products.length; index++) {
        const product = products[index];
        await addProduct({
            clienteId: clientId,
            estacionamentoId: estacionamentoId,
            descricao: product.descricao,
            valor: product.valor,
            valorCusto: product.valorCusto,
            margemLucro: product.margemLucro,
            tipoProduto: product.tipoProduto,
            createdBy: email,
            revendaId: idRevenda
        })
    }

    await addVerify({
        clientId: clientId,
        nome: nome,
        email: email,
        phone: phone,
        comoConheceu: comoConheceu,
        revendaId: idRevenda
    })

    await addPrismSettings({
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
        user: {
            nome: nome,
            email: email
        }
    })

    await addQueue({
        data: {
            revendaId: revendaId,
            clienteId: clientId,
            estacionamentoId: estacionamentoId,
            createAt: new Date(),
            origin: "Nova conta",
            type: "NEW_ACCOUNT",
            createdBy: {
                id: user.id,
                nome: user.nome,
                email: user.email,
            },
            body: {
                clienteId: clientId
            }
        }
    })

    completed(user.id)
}

export const doLogin = async ({ email, password }, updateStatus, error, completed) => {
    if (email === '') {
        error('Informe o e-mail ou operador.')
        return
    }
    if (password === '') {
        error('Informe a senha.')
        return
    }
    updateStatus({ progress: 10 })
    error('')
    const response = await getUser({ email: email, password: password })
    updateStatus({ progress: 20 })
    if (response.success) {
        const user = response.data
        if (user.status === 'Inativo') {
            error('Este usuário não esta ativo, entre em contato com o administrador!')
            return
        }
        var client = null
        var revenda = null
        if (user.clienteId) {
            client = await getClientById({ id: user.clienteId })
            revenda = await getRevendaById({ id: client.revendaId })
        } else {
            revenda = await getRevendaById({ id: user.revendaId })
        }
        if (revenda) {
            const url = await getURLFromURI(revenda.logoAdministrativo)
            revenda.logoAdministrativoUrl = url
        }
        // const alloweds = revenda.revendasAssociadas?.map(e => e.value) ?? []
        // if (alloweds.length !== 0 && alloweds.indexOf(user.revendaId) === -1) {
        //     error("Este usuário não tem permissão para acessar o sistema desta plataforma.1")
        //     return
        // } else if (user.revendaId !== getResellerIdByEnv()) {
        //     error("Este usuário não tem permissão para acessar o sistema desta plataforma.2")
        //     return
        // }
        if (user.administrativo || user.revendedor) {
            let sessao = { usuario: user, revenda: revenda }
            completed(sessao)
        } else {
            const profile = await getProfileById({ id: user.perfilId })
            updateStatus({ progress: 30 })
            if (profile) {
                if (profile.accessWebSite === false || profile.accessWebSite === undefined) {
                    error('Você não tem permissão para fazer login no site administrativo. Entre em contato com o seu encarregado ou administrador do sistema.')
                } else {
                    updateStatus({ progress: 60 })
                    const parks = await getAllParksByClientId({ clientId: user.clienteId })
                    updateStatus({ progress: 80 })
                    const licences = await getLicencas({ clientId: user.clienteId })
                    updateStatus({ progress: 100 })
                    let sessao = {
                        usuario: user,
                        cliente: client,
                        estacionamentos: parks,
                        estacionamento: parks[0],
                        licencas: licences.data,
                        perfil: profile,
                        revenda: revenda
                    }
                    completed(sessao)
                }
            }
        }
    } else {
        error(response.message)
    }
}

export const sendDownloadApp = async ({ phone }, loading, completed) => {
    const newPhone = phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "")
    if (newPhone.length !== 11 && newPhone.length !== 10) {
        return
    }
    loading(true)
    await sendTemplateWhatsApp({
        template: "DOWNLOAD_APP",
        data: {
            phone: newPhone
        }
    })
    completed("Aplicativo enviado com sucesso :)")
}

const validateFields = ({ nome, email, phone, park, document, password, comoConheceu }) => {
    if (email === '') {
        return { success: false, message: 'Informe um e-mail válido.\n\nLembre-se, o e-mail será utilizado para fazer login.' }
    }
    if (password?.length < 6) {
        return { success: false, message: 'A senha precisa ter no minímo 6 caracteres.' }
    }
    if (nome === '') {
        return { success: false, message: 'Informe seu nome.' }
    }
    const newPhone = phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "")
    if (newPhone.length !== 11 && newPhone.length !== 10) {
        return { success: false, message: 'Informe um número comercial válido.' }
    }
    const newDocument = normalizedField(document)
    if (!isValidDocument(newDocument)) {
        return { success: false, message: 'Informe um número de documento válido.' }
    }
    if (document === '') {
        return { success: false, message: 'Informe o documento.' }
    }
    if (park === '') {
        return { success: false, message: 'Informe o nome do estabelecimento.' }
    }
    if (comoConheceu === '') {
        return { success: false, message: 'Informe como nos conheceu.' }
    }
    return { success: true }
}