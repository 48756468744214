import React from 'react'
import { NavBar } from '../../components/navbar/index.js'
import { Loading, EmptyContent } from '../../components/common/commons.js'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getCliente, getEstacionamento } from '../../config/auth.js';
import Tooltip from '@mui/material/Tooltip';
import { documentMask, generateUUIDV7, goToNewWindow, isNullOrEmpty, queryString, reloadWithAlert, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastSuccess, toastWarning, toCurrency } from '../../shared/utils.js';
import { getAllEventsByParkId, getEventById } from '../../store/collections/eventWorker.js';
import { addEvent, removeEventById } from '../../store/collections/eventWorker.js';
import { goToWindow } from '../../shared/utils.js';
import { getAllEventsCouponByEventAndParkId, removeEventCouponById } from '../../store/collections/eventCouponWorker.js';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { TextField } from '@mui/material';
import { getURLFromURI, uploadEventImage } from '../../store/filesWorker.js';
import { sendLogByUser } from '../../shared/analytics.js';
import { getAllProductsByParkId } from '../../store/collections/productWorker.js';
import { getAllActivePaymentTables } from '../../store/collections/paymentTableWorker.js';
import { AlertWarning } from '../../shared/alert-manager.js';

const initializeState = {
    id: queryString("id"),
    estacionamentoId: getEstacionamento()?.id,
    clienteId: getCliente()?.id,
    revendaId: getEstacionamento()?.revendaId,
    name: '',
    description: '',
    startDate: new Moment(new Date()),
    endDate: new Moment(new Date()),
    status: "draft",
    imageURL: "https://placehold.co/1x1?text=Capa",
    couponsAvailable: 0,
    priceType: "",
    products: [],
    paymentTables: [],
}

class EventsList extends React.Component {

    render() {
        return (
            <NavBar>
                <EventsListBody />
            </NavBar>
        )
    }
}

class EventsListBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = initializeState
    }

    componentDidMount = async () => {
        await this.load(true)
    }

    load = async (isLoading) => {
        this.setState({ isLoading: isLoading })
        var items = await getAllEventsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    newEvent = () => {
        this.cleanFields()
        this.setState({ isShowingEventModal: true })
    }

    goTickets = (item) => {
        goToWindow("/cadastrar/evento/ingressos/?eventId=" + item.id)
    }

    onEdit = async (item) => {
        this.setState({
            ...initializeState,
            ...item
        }, async () => {
            if (!isNullOrEmpty(item.imageURL) && item.imageURL !== "https://placehold.co/1x1?text=Capa") {
                toastLoading("Aguarde...")
                const url = await getURLFromURI(item.imageURL)
                this.setState({ imageURL: url })
            }
            this.setState({ startDate: Moment(item.startDate.seconds * 1000) })
            this.setState({ endDate: Moment(item.endDate.seconds * 1000) })
            this.changePriceType(item.priceType, item.ids)
            this.setState({ isShowingEventModal: true })
            toastDismissLoading()
        })
    }

    onDuplicate = (item) => {
        item.id = null
        this.onEdit(item)
    }

    save = async () => {
        if (!this.checkFields()) {
            toastWarning("Prencha os campos obrigatórios")
            return
        }
        toastLoading("Salvando evento...")
        const data = this.retriveData()
        const file = this.inputImageElement.files[0]
        if (file) {
            const destination = await uploadEventImage(this.state.estacionamentoId, file, data.id)
            data.imageURL = destination
        }
        await addEvent({ data: data })
        toastDismissLoading()
        toastSuccess("Evento salvo com sucesso!")
        this.setState({ isShowingEventModal: false })
        await this.load()
        this.cleanFields()
    }

    cleanFields = () => {
        this.setState({ ...initializeState })
    }

    retriveData = () => {
        var data = {
            id: this.state.id ?? generateUUIDV7(),
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            revendaId: this.state.revendaId,
            name: this.state.name,
            startDate: this.state.startDate.toDate(),
            endDate: this.state.endDate.toDate(),
            status: this.state.status,
            imageURL: this.state.imageURL,
            price: this.state.price,
            priceType: this.state.priceType,
            description: this.state.description,
            couponsAvailable: parseInt(this.state.couponsAvailable),
        }
        if (this.state.priceType === "products") {
            data.ids = this.state.products.filter(e => e.isChecked).map(e => e.id)
        } else {
            data.ids = this.state.paymentTables.filter(e => e.isChecked).map(e => e.id)
        }
        return data
    }

    checkFields = () => {
        return (isNullOrEmpty(this.state.name) ||
            this.state.startDate.isBefore(Moment()) ||
            this.state.endDate.isBefore(Moment()) ||
            parseInt(this.state.couponsAvailable) <= 0)
    }

    onRemove = async (item) => {
        var confirm = window.confirm(`Confirma mesmo a remoção deste evento?`);
        if (confirm) {
            toastLoading("Verificando evento...")
            const coupons = await getAllEventsCouponByEventAndParkId({ eventId: item.id, estacionamentoId: this.state.estacionamentoId })
            if (coupons.filter(e => e.status == "paid").length > 0) {
                toastDismissLoading()
                toastWarning("Este evento possui ingressos pagos. Não é possível remover.\nAntes você deve cancelar os pagamentos.")
            } else {
                toastLoading("Removendo evento...")
                await removeEventById({ id: item.id })
                await this.load()
                toastDismissLoading()
                toastSuccess("Evento removido com sucesso.")
            }
        }
    }

    handleStartDateChange = (date) => {
        this.setState({ startDate: date })
    }

    handleEndDateChange = (date) => {
        this.setState({ endDate: date })
    }

    handleClick = (e) => {
        this.inputImageElement.click();
    }

    onChangeFile = (e) => {
        const imageURL = URL.createObjectURL(e.target.files[0])
        this.setState({ imageURL: imageURL })
    }

    onChangePrice = (a, b, floatvalue) => {
        this.setState({ price: floatvalue })
    }

    changePriceType = async (value, ids) => {
        this.setState({ priceType: value })
        if (value == "products") {
            this.loadProducts(ids)
        } else {
            this.loadPaymentTables(ids)
        }
    }

    loadProducts = async (ids) => {
        if (this.state.products.length == 0) {
            toastLoading("Buscando produtos...")
            var products = await getAllProductsByParkId({ estacionamentoId: this.state.estacionamentoId })
            if (ids) {
                products = products.map((e) => { return { ...e, isChecked: ids.includes(e.id) } })
                this.setState({ products: products })
            } else {
                this.setState({ products: products })
            }
            toastDismissLoading()
        }
    }

    loadPaymentTables = async (ids) => {
        if (this.state.paymentTables.length == 0) {
            toastLoading("Buscando tabelas de preços...")
            var paymentTables = await getAllActivePaymentTables({ estacionamentoId: this.state.estacionamentoId })
            if (ids) {
                paymentTables = paymentTables.map((e) => { return { ...e, isChecked: ids.includes(e.id) } })
                this.setState({ paymentTables: paymentTables })
            } else {
                this.setState({ paymentTables: paymentTables })
            }
            toastDismissLoading()
        }
    }

    changeCheckedProduct = (item, isChecked) => {
        if (item.estoqueDisponivel <= 0 && isChecked === true) {
            toastWarning("Este produto não tem estoque disponível.")
            item.isChecked = false
            this.setState({ products: this.state.products })
        } else {
            item.isChecked = isChecked
            this.setState({ products: this.state.products })
        }
    }

    changeCheckedPaymentTable = (item, isChecked) => {
        item.isChecked = isChecked
        this.setState({ paymentTables: this.state.paymentTables })
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Eventos
                    <div className='btn-group'>
                        <button type="button" onClick={() => { this.newEvent() }} className="btn btn-sm btn-primary">
                            <i className="fas fa-add mx-2" />
                            Cadastrar
                        </button>
                    </div>
                </div>
                <div className="mb-4">
                    {
                        this.state.items?.length === 0 && !this.state.isLoading ?
                            <EmptyContent text='Nenhum evento cadastrado.' />
                            :
                            !this.state.isLoading ?
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td width={10} />
                                                        <td align='left'><strong>Nome</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Data início</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Data fim</strong></td>
                                                        <td />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items?.map((item, index) =>
                                                            <tr key={index}>
                                                                <td align='left'>
                                                                    <Tooltip title={item.id} placement="top">
                                                                        <span>
                                                                            {
                                                                                item.status == "published" && <span className="badge text-bg-success w-100">Publicado</span>
                                                                            }
                                                                            {
                                                                                item.status == "draft" && <span className="badge text-bg-secondary w-100">Rascunho</span>
                                                                            }
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td align='left'>{item.name ?? "Não informado"}</td>
                                                                <td align='center'>{Moment(item.startDate.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                <td align='center'>{Moment(item.endDate.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                <td align='right' width={10}>
                                                                    <div className='btn-group'>
                                                                        <Tooltip title="Editar evento" placement="top">
                                                                            <button className='btn btn-secondary' onClick={(e) => this.onEdit(item)}>
                                                                                <i className="fas fa-edit" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Eduplicar evento" placement="top">
                                                                            <button className='btn btn-warning text-white' onClick={(e) => this.onDuplicate(item)}>
                                                                                <i className="fas fa-copy" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Ingressos" placement="top">
                                                                            <button className='btn btn-primary' onClick={(e) => this.goTickets(item)}>
                                                                                <i className="fas fa-ticket-alt" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Apagar evento" placement="top">
                                                                            <button className='btn btn-danger' onClick={(e) => this.onRemove(item)}>
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                    <Dialog fullWidth maxWidth={"sm"} open={this.state.isShowingEventModal} onClose={(e) => this.setState({ isShowingEventModal: false })}>
                        <MuiDialogTitle className='text-center'>
                            {this.state.item?.id ? "Editar evento" : "Cadastrar evento"}
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <div role='button' onClick={this.handleClick}>
                                        <img alt='Imagem do evento' style={{ "height": "180px", "object-fit": "cover" }} className="img-thumbnail my-2" src={this.state.imageURL} />
                                        <input type='file' onChange={this.onChangeFile} accept="image/png, image/jpeg" hidden={true} ref={input => this.inputImageElement = input} />
                                    </div>
                                </div>
                                <div className='col-lg-8'>
                                    <div className='col-lg-12'>
                                        <label>Status</label>
                                        <select className='form-select' value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                                            <option value="draft">Rascunho</option>
                                            <option value="published">Publicado</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-12'>
                                        <label>Nome</label>
                                        <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" />
                                    </div>
                                    <div className='col-lg-12'>
                                        <label>Ingressos disponíveis</label>
                                        <input type="text" pattern="[0-9]" onChange={(e) => this.setState({ couponsAvailable: e.target.value })} value={this.state.couponsAvailable} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label>Descrição</label>
                                    <textarea rows={3} onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} className="form-control" />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 mt-4'>
                                    <MobileDatePicker
                                        label="Data inicial"
                                        inputFormat="DD/MM/yyyy"
                                        value={this.state.startDate}
                                        onChange={this.handleStartDateChange}
                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                    />
                                </div>
                                <div className='col-lg-6 mt-4'>
                                    <MobileDatePicker
                                        label="Data final"
                                        inputFormat="DD/MM/yyyy"
                                        value={this.state.endDate}
                                        onChange={this.handleEndDateChange}
                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label>Tipo cobrança</label>
                                    <select className='form-select' value={this.state.priceType} onChange={(e) => this.changePriceType(e.target.value)}>
                                        <option value="">Selecionar</option>
                                        <option value="products">Produtos</option>
                                        <option value="time">Tempo de permanência</option>
                                    </select>
                                </div>
                                <div className='col-lg-12 mt-3'>
                                    {
                                        this.state.priceType == "products" && this.state.products.length > 0 && <>
                                            <h5 className='text-center'>Produtos disponíveis</h5>
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td width={10} />
                                                            <td align='left'><strong>Nome</strong></td>
                                                            <td align='left'><strong>Tipo</strong></td>
                                                            <td align='center'><strong>Estoque</strong></td>
                                                            <td align='right'><strong>Preço unidade</strong></td>
                                                            <td width={10} />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.products.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td align='left'>
                                                                        <div className="form-check form-switch mt-2">
                                                                            <input className="form-check-input" id="exportFieldType" checked={item.isChecked ?? false} onChange={(e) => this.changeCheckedProduct(item, e.target.checked)} type="checkbox" value={item.id} />
                                                                        </div>
                                                                    </td>
                                                                    <td align='left'>{item.descricao ?? "Não informado"}</td>
                                                                    <td align='left'>{item.tipoProduto === "SELL" ? "Venda" : "Serviço"}</td>
                                                                    <td align='center'>{item.estoqueDisponivel ?? 0}</td>
                                                                    <td align='right'>{toCurrency(item.valor ?? item.valorOnline ?? 0)}</td>
                                                                    <td>
                                                                        <div className="btn-group">
                                                                            <Tooltip title="Visualizar" placement="top">
                                                                                <button type="button" onClick={(e) => goToNewWindow(`/cadastrar/produto/?id=${item.id}`)} className="btn btn-sm btn-secondary">
                                                                                    <i className="fas fa-edit" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <AlertWarning message="Produtos que chegarem ao estoque zero não serão exibidos para venda." />
                                        </>
                                    }
                                    {
                                        this.state.priceType == "time" && this.state.paymentTables.length > 0 && <>
                                            <h5 className='text-center'>Tabelas de preços disponíveis</h5>
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td width={10} />
                                                            <td align='left'><strong>Nome</strong></td>
                                                            <td align='center'><strong>Período máximo</strong></td>
                                                            <td align='right'><strong>Valor por período</strong></td>
                                                            <td width={10} />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.paymentTables.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td align='left'>
                                                                        <div className="form-check form-switch mt-2">
                                                                            <input className="form-check-input" id="exportFieldType" checked={item.isChecked ?? false} onChange={(e) => this.changeCheckedPaymentTable(item, e.target.checked)} type="checkbox" value={item.id} />
                                                                        </div>
                                                                    </td>
                                                                    <td align='left'>{item.nome ?? "Não informado"}</td>
                                                                    <td align='center'>{item.periodoMaximo ?? 0}</td>
                                                                    <td align='right'>{toCurrency(item.valorMaximo ?? 0)}</td>
                                                                    <td>
                                                                        <div className="btn-group">
                                                                            <Tooltip title="Visualizar" placement="top">
                                                                                <button type="button" onClick={(e) => goToNewWindow(`/cadastrar/tabela-preco/?id=${item.id}`)} className="btn btn-sm btn-secondary">
                                                                                    <i className="fas fa-edit" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={() => this.setState({ isShowingEventModal: false })} className='btn btn-secondary'>Fechar</button>
                                <button onClick={() => this.save()} className='btn btn-success'>Salvar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </div>
            </div >
        )
    }
}

class EventCouponsList extends React.Component {

    render() {
        return (
            <NavBar>
                <EventCouponsListBody />
            </NavBar>
        )
    }
}

class EventCouponsListBody extends React.Component {

    constructor(props) {
        super(props);
        const eventId = queryString("eventId");
        if (isNullOrEmpty(eventId)) {
            toastWarning("Evento não encontrado")
            goToWindow("/cadastrar/evento")
        } else {
            const park = getEstacionamento()
            this.state = {
                item: null,
                eventId: eventId,
                estacionamentoId: park?.id,
                clienteId: park?.clienteId,
                revendaId: park?.revendaId,
                items: [],
                isLoading: true
            }
        }
    }

    componentDidMount = async () => {
        await this.load(true)
    }

    load = async (isLoading) => {
        this.setState({ isLoading: isLoading })
        const event = await getEventById({ id: this.state.eventId })
        this.setState({ event: event })

        var items = await getAllEventsCouponByEventAndParkId({ eventId: this.state.eventId, estacionamentoId: this.state.estacionamentoId })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    onRefound = (item) => {

    }

    onRemove = async (item) => {
        var confirm = window.confirm(`Deseja mesmo remover o ingresso do cliente ${item.client.name}?`);
        if (confirm) {
            toastLoading("Removendo...")
            await removeEventCouponById({ id: item.id })
            sendLogByUser("Event coupon", `Remover o ingresso %{item.id}`, item.id)
            toastDismissLoading()
            reloadWithAlert("Ingresso removido com sucesso.")
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Ingressos do evento: {takeIfIsNotNullOrEmpty(this.state.event?.name)}
                </div>
                <div className="mb-4">
                    {
                        this.state.items.length === 0 && !this.state.isLoading ?
                            <EmptyContent text='Nenhuma venda encontrada para este evento.' />
                            :
                            !this.state.isLoading ?
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td width={10} />
                                                        <td align='left'><strong>Cliente</strong></td>
                                                        <td align='left'><strong>Documento</strong></td>
                                                        <td align='center'><strong>Data da compra</strong></td>
                                                        <td align='center'><strong>Comparecimento</strong></td>
                                                        <td align='right'><strong>Valor</strong></td>
                                                        <td />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                <td align='left'>
                                                                    <Tooltip title={`${item.id} - ${item.groupId}`} placement="top">
                                                                        <span>
                                                                            {
                                                                                item.status == "paid" && <span className="badge text-bg-success w-100">Pago</span>
                                                                            }
                                                                            {
                                                                                item.status == "pending" && <span className="badge text-bg-warning w-100 text-white">Pendente</span>
                                                                            }
                                                                            {
                                                                                item.status == "expired" && <span className="badge text-bg-warning w-100 text-white">Expirado</span>
                                                                            }
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td align='left'>{item.client.name ?? "Não informado"}</td>
                                                                <td align='left'>{documentMask(item.client.document)}</td>
                                                                <td align='center'>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                <td align='center'>
                                                                    {
                                                                        Moment(item.startDate.seconds * 1000).isSame(Moment(item.endDate.seconds * 1000), 'day') ?
                                                                            <span>{Moment(item.startDate.seconds * 1000).format("DD/MM/YYYY")}</span>
                                                                            :
                                                                            <span>{Moment(item.startDate.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")} - {Moment(item.endDate.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</span>
                                                                    }
                                                                </td>
                                                                <td align='right'>{toCurrency(item.total)}</td>
                                                                <td align='right' width={10}>
                                                                    <div className='btn-group'>
                                                                        {
                                                                            item.status == "paid" &&
                                                                            <Tooltip title="Extornar" placement="top">
                                                                                <button className='btn btn-danger' onClick={(e) => this.onRefound(item)}>
                                                                                    <i class="fa-solid fa-strikethrough" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                        {
                                                                            (item.status == "pending" || item.status == "expired") &&
                                                                            <Tooltip title="Apagar" placement="top">
                                                                                <button className='btn btn-danger' onClick={(e) => this.onRemove(item)}>
                                                                                    <i class="fa-solid fa-trash" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </div >
        )
    }
}

export { EventsList, EventCouponsList }