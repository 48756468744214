import React from 'react'
import 'firebase/auth';
import 'firebase/storage';
import { NavBar } from '../../../components/navbar'
import { getCliente, getEstacionamento, isAdministrador } from '../../../config/auth'
import { HelpButton, Loading } from '../../../components/common/commons'
import NumberFormat from 'react-number-format';
import { Tabs, Tab } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { searchCEP } from '../../../shared/cepWorker';
import Tooltip from '@mui/material/Tooltip';
import { fetchFunction } from '../../../shared/function-request';
import { getParameter, updateParameterById } from '../../../store/collections/parameterWorker';
import { saveLastUpdate } from '../../../store/collections/lastUpdateWorker';
import { queryString, setPathWindow, toastDismissLoading, toastLoading, toastSuccess } from '../../../shared/utils';
import { AlertDanger, AlertWarning } from '../../../shared/alert-manager';

const initialState = {
    id: null,
    estacionamentoId: getEstacionamento()?.id,
    clienteId: getCliente()?.id,
    isLoading: true,
    settings: null
};

class ConfigurarNotaNovo extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
        this.load()
    }

    load = async () => {
        const option = {
            method: "GET"
        }
        const result = await fetchFunction("/newnote/company", option)
        toastDismissLoading()
        this.setState({ isLoading: false })
        if (result.success) {
            this.setState({ settings: result.data })
        }
    }

    update = async (settings) => {
        const option = {
            method: "POST",
            body: {
                ...settings,
                email: "financeiro@web.com.br"
            }
        }
        var parameter = await getParameter()
        parameter.habilitarEmissaoNotaV2 = settings.habilitarEmissaoNota
        parameter.habilitarEmissaoNota = settings.habilitarEmissaoNota
        parameter.habilitarNotaAvulsa = settings.habilitarNotaAvulsa
        parameter.habilitarEmissaoNotaNFCE = settings.habilitarEmissaoNotaNFCE
        parameter.habilitarEmissaoNotaParaTodosOsPagamentos = settings.habilitarEmissaoNotaParaTodosOsPagamentos
        parameter.habilitarEmissaoNotaMostrarTomador = settings.habilitarEmissaoNotaMostrarTomador
        parameter.habilitarEmissaoNotaPorTipo = settings.habilitarEmissaoNotaPorTipo
        parameter.habilitarEmissaoNotaMostrarCampoEmail = settings.habilitarEmissaoNotaMostrarCampoEmail
        parameter.habilitarEmissaoNotaMostrarCampoEndereco = settings.habilitarEmissaoNotaMostrarCampoEndereco
        parameter.habilitarEmissaoNotaCampoEnderecoObrigatorio = settings.habilitarEmissaoNotaCampoEnderecoObrigatorio
        parameter.habilitarEmissaoNotaMostrarCampoNome = settings.habilitarEmissaoNotaMostrarCampoNome
        parameter.habilitarEmissaoNotaSelecionarDataServico = settings.habilitarEmissaoNotaSelecionarDataServico
        parameter.habilitarEmissaoNotaPorTempo = settings.habilitarEmissaoNotaPorTempo
        await updateParameterById({ id: parameter.id, data: parameter })
        await saveLastUpdate()
        const result = await fetchFunction("/newnote/company", option)
        toastDismissLoading()
        toastSuccess(result.message)
    }

    goToOldVersion = () => {
        window.location.href = "/v1/nota-fiscal/configurar"
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Configurar empresa
                                    <div className='btn-group'>
                                        <a href='/nota-fiscal/municipios-homologados' target='_blank' className='btn btn-success btn-sm'>Municípios homologados</a>
                                        <HelpButton />
                                    </div>
                                </div>
                                <div className="card-body">
                                    {
                                        this.state.isLoading && <Loading />
                                    }
                                    {
                                        !this.state.isLoading &&
                                        <ConfigurarNotaNovoBody key={this.state.settings} settings={this.state.settings} save={this.update} showAppTab={true} showTabAddress={true} />
                                    }
                                </div>
                            </div>
                        </div >
                    </div >
                    <Dialog maxWidth='sm' fullWidth open={this.state.isOpenShowOldVersion}>
                        <MuiDialogTitle className='text-center'>
                            Estamos sempre inovando
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <h6 className='text-center'>Alteramos a forma de emissão de notas para proporcionar uma melhor esperiência <strong className='text-success'>aconselhamos fortemente</strong> que seja configurado as novas funcionalidades nesta tela.</h6>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={(e) => this.setState({ isOpenShowOldVersion: false })} className='btn btn-success text-white'>Cancelar</button>
                                <button onClick={this.goToOldVersion} className='btn btn-secondary'>Acessar painel antigo</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar >
            </>
        )
    }
}

class ConfigurarNotaNovoBody extends React.Component {

    constructor(props) {
        super(props)
        const data = {
            id: null,
            isLoading: true,
            fromRessale: props.fromRessale,
            isAdministrador: isAdministrador(),
            key: "endereco",
            unidade_empresa: "matriz",
            tipo_acesso: "certificado",
            isShowingCSCModal: false,
            habilitarEmissaoNota: false,
            habilitarNotaAvulsa: true,
            habilitarEmissaoNotaParaTodosOsPagamentos: false,
            habilitarEmissaoNotaMostrarTomador: true,
            habilitarEmissaoNotaPorTempo: "IMEDIATAMENTE",
            habilitarEmissaoNotaPorTipo: "TOTAL",
            regime_tributario: "lucro_real",
            natureza_operacao: "1",
            cep: "",
            iss: 0.0,
            pis: 0.0,
            cofins: 0.0,
            inss: 0.0,
            ir: 0.0,
            csll: 0.0,
            cp: 0.0,
            showTabAddress: props.showTabAddress,
            showAppTab: props.showAppTab,
            isNewRPSNFCE: (props.settings?.nfce_serie === undefined || props.settings?.nfce_serie === null),
            ...props.settings
        }
        this.state = data
    }

    componentDidMount = async () => {
        if (this.state.showTabAddress) {
            const tab = queryString("tab")
            this.changeTab(tab ?? "endereco")
        }
        this.setState({ tomador_anonimo_nome_temp: this.state.tomador_anonimo_nome })
        this.setState({ tomador_anonimo_documento_temp: this.state.tomador_anonimo_documento })
        this.setState({ tomador_anonimo_cep_temp: this.state.tomador_anonimo_cep })
        this.setState({ tomador_anonimo_logradouro_temp: this.state.tomador_anonimo_logradouro })
        this.setState({ tomador_anonimo_numero_temp: this.state.tomador_anonimo_numero })
        this.setState({ tomador_anonimo_bairro_temp: this.state.tomador_anonimo_bairro })
        this.setState({ tomador_anonimo_cidade_temp: this.state.tomador_anonimo_cidade })
        this.setState({ tomador_anonimo_estado_temp: this.state.tomador_anonimo_estado })
        this.setState({ tomador_anonimo_complemento_temp: this.state.tomador_anonimo_complemento })
    }

    retriveData = () => {
        return {
            unidade_empresa: this.state.unidade_empresa,
            cnpj: this.state.cnpj,
            razao_social: this.state.razao_social,
            im: this.state.im,
            ie: this.state.ie,
            cep: this.state.cep,
            endereco: this.state.endereco,
            numero: this.state.numero,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            uf: this.state.uf,
            ibge: this.state.ibge,
            complemento: this.state.complemento,
            tipo_tributacao: this.state.tipo_tributacao,
            regime_tributario: this.state.regime_tributario,
            regime_apuracao_sn: this.state.regime_apuracao_sn,
            natureza_operacao: this.state.natureza_operacao,
            iss_retido: this.state.iss_retido,
            responsavel_retencao_iss: this.state.responsavel_retencao_iss,
            exigibilidade_iss: this.state.exigibilidade_iss,
            codigo_tributacao_municipio: this.state.codigo_tributacao_municipio,
            iss: this.state.iss,
            codigo_servico: this.state.codigo_servico,
            cnae: this.state.cnae,
            informacoes_fisco: this.state.informacoes_fisco,
            tipo_acesso: this.state.tipo_acesso,
            nfse_login: this.state.nfse_login,
            nfse_password: this.state.nfse_password,
            certificado_senha: this.state.certificado_senha,
            nfce_id_csc: this.state.nfce_id_csc,
            nfce_codigo_csc: this.state.nfce_codigo_csc,
            habilitarEmissaoNota: this.state.habilitarEmissaoNota,
            habilitarEmissaoNotaV2: this.state.habilitarEmissaoNotaV2,
            habilitarNotaAvulsa: this.state.habilitarNotaAvulsa,
            habilitarEmissaoNotaNFCE: this.state.habilitarEmissaoNotaNFCE,
            habilitarEmissaoNotaParaTodosOsPagamentos: this.state.habilitarEmissaoNotaParaTodosOsPagamentos,
            habilitarEmissaoNotaMostrarTomador: this.state.habilitarEmissaoNotaMostrarTomador,
            habilitarEmissaoNotaPorTempo: this.state.habilitarEmissaoNotaPorTempo,
            habilitarEmissaoNotaPorTipo: this.state.habilitarEmissaoNotaPorTipo,
            habilitarEmissaoNotaMostrarCampoNome: this.state.habilitarEmissaoNotaMostrarCampoNome,
            habilitarEmissaoNotaSelecionarDataServico: this.state.habilitarEmissaoNotaSelecionarDataServico,
            habilitarEmissaoNotaMostrarCampoEmail: this.state.habilitarEmissaoNotaMostrarCampoEmail,
            habilitarEmissaoNotaMostrarCampoEndereco: this.state.habilitarEmissaoNotaMostrarCampoEndereco,
            habilitarEmissaoNotaCampoEnderecoObrigatorio: this.state.habilitarEmissaoNotaCampoEnderecoObrigatorio,
            habilitarEmissaoNotaComTomadorAnonimo: this.state.habilitarEmissaoNotaComTomadorAnonimo,
            certificado: this.state.certificado,
            tomador_anonimo_nome: this.state.tomador_anonimo_nome,
            tomador_anonimo_documento: this.state.tomador_anonimo_documento,
            tomador_anonimo_cep: this.state.tomador_anonimo_cep,
            tomador_anonimo_logradouro: this.state.tomador_anonimo_logradouro,
            tomador_anonimo_numero: this.state.tomador_anonimo_numero,
            tomador_anonimo_bairro: this.state.tomador_anonimo_bairro,
            tomador_anonimo_cidade: this.state.tomador_anonimo_cidade,
            tomador_anonimo_estado: this.state.tomador_anonimo_estado,
            tomador_anonimo_complemento: this.state.tomador_anonimo_complemento,
            emissor_nfse: this.state.emissor_nfse ?? "webmania",
            emissor_nfce: this.state.emissor_nfce ?? "webmania"
        }
    }

    update = () => {
        if (this.props.save) {
            var data = this.retriveData()
            this.props.save(data)
        }
    }

    updateRPS = () => {
        if (this.props.save) {
            toastLoading("Salvando...")
            var data = this.retriveData()
            data.serie_rps = this.state.serie_rps
            data.nfse_rps_numero = parseInt(this.state.nfse_rps_numero)
            this.props.save(data)
        }
    }

    updateRPSNFCE = () => {
        if (this.props.save) {
            toastLoading("Salvando...")
            var data = this.retriveData()
            data.nfce_serie = this.state.nfce_serie
            data.nfce_numero = parseInt(this.state.nfce_numero)
            this.props.save(data)
        }
    }

    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.toString().substr(reader.result.toString().indexOf(',') + 1));
        reader.onerror = reject;
    });

    loadCertificate = async (e) => {
        const file = e.target.files[0]
        if (file) {
            const certificado = await this.toBase64(file)
            this.setState({ certificado: certificado })
        }
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result) {
            this.setState({ cep: result.cep })
            this.setState({ endereco: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ ibge: result.ibge })
            this.setState({ uf: result.uf })
        }
    }

    fetchCEPAnonymous = async (value) => {
        this.setState({ tomador_anonimo_cep_temp: value })
        const result = await searchCEP(value)
        if (result) {
            this.setState({ tomador_anonimo_cep_temp: result.cep })
            this.setState({ tomador_anonimo_logradouro_temp: result.logradouro })
            this.setState({ tomador_anonimo_complemento_temp: result.complemento })
            this.setState({ tomador_anonimo_bairro_temp: result.bairro })
            this.setState({ tomador_anonimo_cidade_temp: result.localidade })
            this.setState({ tomador_anonimo_estado_temp: result.uf })
        }
    }

    changeTab = (tab) => {
        if (!this.state.fromRessal) {
            setPathWindow(`/nota-fiscal/configurar?tab=${tab}`)
        }
        this.setState({ key: tab })
    }

    showRPSEdit = () => {
        this.setState({ isShowingRPSModal: true })
    }

    showSerieNFCEdit = () => {
        this.setState({ isShowingSerieNFCEModal: true })
    }

    savaAnonymoyIssuer = () => {
        this.setState({ tomador_anonimo_nome: this.state.tomador_anonimo_nome_temp })
        this.setState({ tomador_anonimo_documento: this.state.tomador_anonimo_documento_temp })
        this.setState({ tomador_anonimo_cep: this.state.tomador_anonimo_cep_temp })
        this.setState({ tomador_anonimo_logradouro: this.state.tomador_anonimo_logradouro_temp })
        this.setState({ tomador_anonimo_numero: this.state.tomador_anonimo_numero_temp })
        this.setState({ tomador_anonimo_bairro: this.state.tomador_anonimo_bairro_temp })
        this.setState({ tomador_anonimo_cidade: this.state.tomador_anonimo_cidade_temp })
        this.setState({ tomador_anonimo_estado: this.state.tomador_anonimo_estado_temp })
        this.setState({ tomador_anonimo_complemento: this.state.tomador_anonimo_complemento_temp })
        this.hideAnonymouIssuer()
    }

    showAnonymouIssuer = () => {
        this.setState({ isShowAnonymouIssuer: true })
    }

    hideAnonymouIssuer = () => {
        this.setState({ isShowAnonymouIssuer: false })
    }

    render() {
        return <>
            <div className='row'>
                <div className='col-lg-4'>
                    <div className='row'>
                        <AlertDanger message="As informações são de responsabilidade do cliente, antes de emitir as primeiras notas confira com o seu contador os dados que foram preenchidos." />
                        <div className='col-lg-6'>
                            <label>Tipo da unidade</label>
                            <select className="form-select" value={this.state.unidade_empresa} onChange={(e) => this.setState({ unidade_empresa: e.target.value })} v>
                                <option value=''>Selecionar</option>
                                <option value="matriz">Matriz</option>
                                <option value="filial">Filial</option>
                            </select>
                        </div>
                        <div className='col-lg-6'>
                            <label>CNPJ</label>
                            <NumberFormat className="form-control" mask='_' format={'##.###.###/####-##'} value={this.state.cnpj} onChange={(e) => this.setState({ cnpj: e.target.value })} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <label>Razão Social</label>
                            <input autoComplete='off' type="text" onChange={(e) => this.setState({ razao_social: e.target.value })} value={this.state.razao_social} className="form-control" />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <label>Inscrição Municipal</label>
                            <input autoComplete='off' type="text" onChange={(e) => this.setState({ im: e.target.value })} value={this.state.im} className="form-control" />
                        </div>
                        <div className='col-lg-6'>
                            <label>Inscrição Estadual</label>
                            <Tooltip role="button" title="Opcional" placement="top">
                                <i role='button' className="fas fa-info-circle mx-2" />
                            </Tooltip>
                            <input autoComplete='off' type="text" onChange={(e) => this.setState({ ie: e.target.value })} value={this.state.ie} className="form-control" />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <label>Tipo tributação</label>
                            <select className="form-select" value={this.state.tipo_tributacao} onChange={(e) => this.setState({ tipo_tributacao: e.target.value })} v>
                                <option value=''>Selecionar</option>
                                <option value="mei">MEI</option>
                                <option value="simples_nacional">Simples nacional</option>
                                <option value="simples_nacional_sublimite">Simples nacional sublimite</option>
                                <option value="lucro_normal">Lucro normal</option>
                            </select>
                        </div>
                        <div className='col-lg-6'>
                            <label>Regime tributação</label>
                            <select className="form-select" value={this.state.regime_tributario} onChange={(e) => this.setState({ regime_tributario: e.target.value })} v>
                                <option value="">Não tem regime de tributação</option>
                                <option value="lucro_real">Lucro real</option>
                                <option value="lucro_presumido">Lucro presumido</option>
                            </select>
                        </div>
                        <div className='col-lg-6'>
                            <label>Código tributação munícipio</label>
                            <input type="text" onChange={(e) => this.setState({ codigo_tributacao_municipio: e.target.value })} value={this.state.codigo_tributacao_municipio} className="form-control" />
                        </div>
                        <div className='col-lg-6'>
                            <label>CNAE</label>
                            <input autoComplete='off' type="text" onChange={(e) => this.setState({ cnae: e.target.value })} value={this.state.cnae} className="form-control" />
                        </div>
                        <div className="col-md-12">
                            <label>Informações ao Fisco</label>
                            {
                                this.state.fromRessale ?
                                    <textarea rows={3} type="text" onChange={(e) => this.setState({ informacoes_fisco: e.target.value })} value={this.state.informacoes_fisco} className="form-control" />
                                    :
                                    <textarea rows={3} type="text" placeholder='Informação expressa em todas as emissões. Exemplo: Guarda e estacionamento do tipo "valet service"' onChange={(e) => this.setState({ informacoes_fisco: e.target.value })} value={this.state.informacoes_fisco} className="form-control" />
                            }
                        </div>
                    </div>
                    <div className='col-lg-12 mt-3'>
                        <Tooltip role="button" title="Toque para buscar os dados corretos" placement="top">
                            <a href='https://solucoes.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp' target='_blank'>
                                <div className='alert alert-success' role='button'>
                                    <span className="badge text-bg-success text-white">Informação</span> <br />
                                    É possível consultar os dados da empresa no link abaixo <br />
                                    <strong>Cadastro Nacional da Pessoa Jurídica da Receita Federal.</strong>
                                    <i className="fas fa-external-link-square-alt mx-2" />
                                </div>
                            </a>
                        </Tooltip>
                    </div>
                </div>
                <div className='col-lg-8'>
                    <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={tab => this.changeTab(tab)}>
                        <Tab eventKey="endereco" title="Endereço">
                            <div className='col-lg-12 mt-3'>
                                <div className='row'>
                                    <div className="col-lg-3">
                                        <label>CEP</label>
                                        <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Logradouro</label>
                                        <input type="text" onChange={(e) => this.setState({ endereco: e.target.value })} value={this.state.endereco} className="form-control" />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Número</label>
                                        <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-3">
                                        <label>Bairro</label>
                                        <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Cidade</label>
                                        <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Estado</label>
                                        <select name="estado" className="form-select" onChange={(e) => this.setState({ uf: e.target.value })} value={this.state.uf} >
                                            <option value="">Selecionar</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espírito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP" selected="">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Complemento (opcional)</label>
                                        <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="nota-fiscal-servico" title="NFS-e">
                            <div className='col-lg-12 mt-3'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <label>Regime de Apuração Tributária</label>
                                        <select className="form-select" value={this.state.regime_apuracao_sn} onChange={(e) => this.setState({ regime_apuracao_sn: e.target.value })} v>
                                            <option value=''>Selecionar</option>
                                            <option value="1">1 - Regime de apuração dos tributos federais e municipal pelo SN</option>
                                            <option value="2">2 - Regime de apuração dos tributos federais pelo SN e o ISSQN pela NFS-e conforme respectiva legislação municipal do tributo</option>
                                            <option value="3">3 - Regime de apuração dos tributos federais e municipal pela NFS-e conforme respectivas legilações federal e municipal de cada tributo</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-3'>
                                        <label>Natureza da operação</label>
                                        <select className="form-select" value={this.state.natureza_operacao} onChange={(e) => this.setState({ natureza_operacao: e.target.value })}>
                                            <option value="1">Tributação no município</option>
                                            <option value="2">Tributação fora do município</option>
                                            <option value="3">Isenção</option>
                                            <option value="4">Imune</option>
                                            <option value="5">Exigibilidade suspensa por decisão judicial</option>
                                            <option value="6">Exigibilidade suspensa por procedimento administrativo</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-3'>
                                        <label>ISS Retido</label>
                                        <select className="form-select" value={this.state.iss_retido} onChange={(e) => this.setState({ iss_retido: e.target.value })}>
                                            <option value=''>Selecionar</option>
                                            <option value='1'>Sim</option>
                                            <option value='2'>Não</option>
                                        </select>
                                    </div>
                                    {
                                        this.state.iss_retido === "1" &&
                                        <div className='col-lg-3'>
                                            <label>Responsável pela retenção do ISS</label>
                                            <select className="form-select" value={this.state.responsavel_retencao_iss} onChange={(e) => this.setState({ responsavel_retencao_iss: e.target.value })} v>
                                                <option value=''>Selecionar</option>
                                                <option value='1'>Tomador</option>
                                                <option value='2'>Intermediário</option>
                                            </select>
                                        </div>
                                    }
                                    <div className='col-lg-3'>
                                        <label>Exigibilidade ISS</label>
                                        <select className="form-select" value={this.state.exigibilidade_iss} onChange={(e) => this.setState({ exigibilidade_iss: e.target.value })}>
                                            <option value=''>Selecionar</option>
                                            <option value='1'>1 - Exigível</option>
                                            <option value='2'>2 - Não incidência</option>
                                            <option value='3'>3 - Isenção</option>
                                            <option value='4'>4 - Exportação</option>
                                            <option value='5'>5 - Imunidade</option>
                                            <option value='6'>6 - Exigibilidade suspensa por decisão judicial</option>
                                            <option value='7'>7 - Exigibilidade suspensa por processo administrativo</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-3'>
                                        <label>ISS</label>
                                        <Tooltip role="button" title="Exemplo: 5% = 5.00 ou 2,5% = 2.5" placement="top">
                                            <i role='button' className="fas fa-info-circle mx-2" />
                                        </Tooltip>
                                        <NumberFormat value={this.state.iss} onChange={(e) => this.setState({ iss: parseFloat(e.target.value) })} fixedDecimalScale={false} thousandSeparator={true} className="form-control" />
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Codigo do serviço</label>
                                        <input autoComplete='off' type="text" onChange={(e) => this.setState({ codigo_servico: e.target.value })} value={this.state.codigo_servico} className="form-control" />
                                    </div>
                                    <div className="col-lg-3 mt-4">
                                        <button className='btn btn-warning text-white' onClick={e => this.showRPSEdit()}>Editar Série do RPS</button>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="nota-fiscal-consumo" title="NFC-e">
                            <div className="col-lg-12">
                                <div className="form-check form-switch mt-3">
                                    <input className="form-check-input" id="habilitarEmissaoNotaNFCE" checked={this.state.habilitarEmissaoNotaNFCE} onChange={(e) => this.setState({ habilitarEmissaoNotaNFCE: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="habilitarEmissaoNotaNFCE">
                                        Habilitar emissão de nota de consumo.
                                    </label>
                                </div>
                            </div>
                            {
                                this.state.habilitarEmissaoNotaNFCE &&
                                <>
                                    <div className='col-lg-12 mt-3'>
                                        <div className='row'>
                                            <div className="col-lg-3">
                                                <label className="field-label">ID do Código <strong>CSC</strong></label>
                                                <Tooltip role="button" title="O que é o CSC?" onClick={(e) => this.setState({ isShowingCSCModal: true })} placement="top">
                                                    <i role='button' className="fas fa-info-circle mx-2" />
                                                </Tooltip>
                                                <input type="text" onChange={(e) => this.setState({ nfce_id_csc: e.target.value })} value={this.state.nfce_id_csc} className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="field-label">Código CSC</label>
                                                <input type="text" onChange={(e) => this.setState({ nfce_codigo_csc: e.target.value })} value={this.state.nfce_codigo_csc} className="form-control" />
                                            </div>
                                            {
                                                this.state.isNewRPSNFCE ? <>
                                                    <div className="col-lg-3">
                                                        <label className="field-label">Número de Série</label>
                                                        <input type="text" onChange={(e) => this.setState({ nfce_serie: e.target.value.toUpperCase() })} value={this.state.nfce_serie} className="form-control" />
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <label className="field-label">Número da <strong>PRÓXIMA NOTA FISCAL</strong></label>
                                                        <input type="number" onChange={(e) => this.setState({ nfce_numero: e.target.value })} value={this.state.nfce_numero} className="form-control" />
                                                    </div>
                                                </> :
                                                    <div className="col-lg-2 mt-4">
                                                        <button className='btn btn-warning text-white w-100' onClick={e => this.showSerieNFCEdit()}>Editar Série</button>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <div className='alert alert-warning'>
                                            <div>
                                                <span className="badge text-bg-warning text-white">Atenção</span> <br />
                                                Para emitir nota de consumo é preciso ter um produto cadastrado e devidamente preenchido com os dados fiscais. <br />
                                            </div>
                                            <a href="/cadastrar/produto" className='btn btn-warning text-white' target='_blank'>Visualizar produtos cadastrados</a>
                                        </div>
                                    </div>
                                </>
                            }
                        </Tab>
                        <Tab eventKey="certificado" title="Certificado">
                            <div className='col-lg-12 mt-3'>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <label>Tipo de acesso</label>
                                        <select className="form-select" value={this.state.tipo_acesso} onChange={(e) => this.setState({ tipo_acesso: e.target.value })}>
                                            <option value="certificado">Certificado e senha</option>
                                            <option value="usuario">Usuário e senha</option>
                                        </select>
                                    </div>
                                    {
                                        this.state.tipo_acesso === "usuario" &&
                                        <>
                                            <div className='col-lg-4'>
                                                <label>Login de acesso</label>
                                                <input autoComplete='off' type="text" onChange={(e) => this.setState({ nfse_login: e.target.value })} value={this.state.nfse_login} className="form-control" />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label>Senha de acesso</label>
                                                <input autoComplete='off' type="password" onChange={(e) => this.setState({ nfse_password: e.target.value })} value={this.state.nfse_password} className="form-control" />
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.tipo_acesso === "certificado" &&
                                        <>
                                            <div className='col-lg-4'>
                                                <label>Certificado A1</label>
                                                <input type="file" accept=".p12, .pfx" onChange={this.loadCertificate} id="formFile" />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label>Senha do certificado</label>
                                                <input autoComplete='off' type="password" onChange={(e) => this.setState({ certificado_senha: e.target.value })} value={this.state.certificado_senha} className="form-control" />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Tab>
                        {
                            this.state.showAppTab &&
                            <Tab eventKey="personalizacao" title="Personalização">
                                <div className='row'>
                                    <div className="col-12 col-sm-12 col-lg-12 mt-3">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" id="habilitarEmissaoNota" checked={this.state.habilitarEmissaoNota} onChange={(e) => this.setState({ habilitarEmissaoNota: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="habilitarEmissaoNota">
                                                Habilitar emissão de notas.
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        this.state.habilitarEmissaoNota && <>
                                            <div className="col-12 col-sm-12 col-lg-12">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarNotaAvulsa" checked={this.state.habilitarNotaAvulsa} onChange={(e) => this.setState({ habilitarNotaAvulsa: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarNotaAvulsa">
                                                        Permitir emitir nota avulsa
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarEmissaoNotaMostrarCampoNome" checked={this.state.habilitarEmissaoNotaMostrarCampoNome} onChange={(e) => this.setState({ habilitarEmissaoNotaMostrarCampoNome: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarEmissaoNotaMostrarCampoNome">
                                                        Apresentar campo nome do tomador ( Opcional )
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarEmissaoNotaMostrarCampoEmail" checked={this.state.habilitarEmissaoNotaMostrarCampoEmail} onChange={(e) => this.setState({ habilitarEmissaoNotaMostrarCampoEmail: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarEmissaoNotaMostrarCampoEmail">
                                                        Apresentar campo e-mail do tomador ( Opcional )
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarEmissaoNotaMostrarCampoEndereco" checked={this.state.habilitarEmissaoNotaMostrarCampoEndereco} onChange={(e) => this.setState({ habilitarEmissaoNotaMostrarCampoEndereco: e.target.checked, habilitarEmissaoNotaCampoEnderecoObrigatorio: e.target.checked ? this.state.habilitarEmissaoNotaCampoEnderecoObrigatorio : false })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarEmissaoNotaMostrarCampoEndereco">
                                                        Apresentar campo endereço do tomador ( Opcional )
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                this.state.habilitarEmissaoNotaMostrarCampoEndereco &&
                                                <div className="col-12 col-sm-12 col-lg-12">
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" id="habilitarEmissaoNotaCampoEnderecoObrigatorio" checked={this.state.habilitarEmissaoNotaCampoEnderecoObrigatorio} onChange={(e) => this.setState({ habilitarEmissaoNotaCampoEnderecoObrigatorio: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarEmissaoNotaCampoEnderecoObrigatorio">
                                                            Obrigar que informe o endereço do tomador.
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-12 col-sm-12 col-lg-12">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarEmissaoNotaParaTodosOsPagamentos" checked={this.state.habilitarEmissaoNotaParaTodosOsPagamentos} onChange={(e) => this.setState({ habilitarEmissaoNotaParaTodosOsPagamentos: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarEmissaoNotaParaTodosOsPagamentos">
                                                        Habilitar emissão de notas para todos os pagamentos por padrão.
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                this.state.habilitarEmissaoNotaParaTodosOsPagamentos &&
                                                <div className="col-lg-12">
                                                    <AlertWarning message="em caso de notas para ticket e venda avulsa será preenchido sem tomador ( sem o documento ) em caso de cobrança de mensalista sera preenchido os dados do mensalista ( nome e documento), mas em ambos os casos é possível editar os dados da nota no momento do pagamento." />
                                                </div>
                                            }
                                            <div className="col-12 col-sm-12 col-lg-12">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarEmissaoNotaMostrarTomador" checked={this.state.habilitarEmissaoNotaMostrarTomador} onChange={(e) => this.setState({ habilitarEmissaoNotaMostrarTomador: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarEmissaoNotaMostrarTomador">
                                                        Permitir emitir nota sem documento
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="habilitarEmissaoNotaSelecionarDataServico" checked={this.state.habilitarEmissaoNotaSelecionarDataServico} onChange={(e) => this.setState({ habilitarEmissaoNotaSelecionarDataServico: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarEmissaoNotaSelecionarDataServico">
                                                        Permitir selecionar data do serviço prestado (apenas notas avulsas).
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                this.state.habilitarEmissaoNotaMostrarTomador &&
                                                <div className="col-lg-12">
                                                    <div className="alert alert-warning mt-3" role="alert">
                                                        <span className={`badge text-bg-warning text-white`}>Atenção</span> <br />
                                                        Algumas prefeituras não permitem emitir nota sem documento, mas permitem que seja emitido com um tomador anônimo.
                                                        <div className="col-12 col-sm-12 col-lg-12 mt-2">
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" id="habilitarEmissaoNotaComTomadorAnonimo" checked={this.state.habilitarEmissaoNotaComTomadorAnonimo} onChange={(e) => this.setState({ habilitarEmissaoNotaComTomadorAnonimo: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="habilitarEmissaoNotaComTomadorAnonimo">
                                                                    Habilitar emissão de notas com tomador anônimo.
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.habilitarEmissaoNotaComTomadorAnonimo &&
                                                            <div className='col-lg-3 my-2'>
                                                                <button type="button" onClick={this.showAnonymouIssuer} className="btn btn-warning text-white btn-sm">Configurar tomador anônimo</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label>Tempo para geração de notas:</label>
                                                    <select className="form-select" value={this.state.habilitarEmissaoNotaPorTempo} onChange={(e) => this.setState({ habilitarEmissaoNotaPorTempo: e.target.value })}>
                                                        <option value="IMEDIATAMENTE">Imediatamente ao confirmar os dados da nota</option>
                                                        <option value="OUTRO_DIA">Emitir no dia seguinte no período da manhã</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Calcular total da nota fiscal do ticket com base em:</label>
                                                    <select className="form-select" value={this.state.habilitarEmissaoNotaPorTipo} onChange={(e) => this.setState({ habilitarEmissaoNotaPorTipo: e.target.value })}>
                                                        <option value="TOTAL">Valor total do ticket ( Estacionamento, Produtos e Serviços vendidos )</option>
                                                        <option value="ESTACIONAMENTO">Apenas o valor do estacionamento</option>
                                                        <option value="ESTACIONAMENTO_SERVICOS">Estacionamento e serviços vendidos</option>
                                                        <option value="ESTACIONAMENTO_PRODUTOS">Estacionamento e Produtos vendidos</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </Tab>
                        }
                        {
                            this.state.isAdministrador &&
                            <Tab eventKey="Administrativo" title="Administrativo">
                                <div className='row mt-3'>
                                    <AlertDanger tag="Administradores" message="Visível apenas para administradores" />
                                    <div className='col-lg-3'>
                                        <label>Emissor de NFS-e</label>
                                        <select className="form-select" value={this.state.emissor_nfse} onChange={(e) => this.setState({ emissor_nfse: e.target.value })}>
                                            <option value="webmania">WebMania</option>
                                            <option value="selfnotas">SelfNotas</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-3'>
                                        <label>Emissor de NFC-e</label>
                                        <select className="form-select" value={this.state.emissor_nfce} onChange={(e) => this.setState({ emissor_nfce: e.target.value })}>
                                            <option value="webmania">WebMania</option>
                                            {/* <option value="selfnotas">SelfNotas</option> */}
                                        </select>
                                    </div>
                                </div>
                            </Tab>
                        }
                    </Tabs>
                    <div className="alert alert-info mt-3">
                        <div>
                            <span className="badge text-bg-info text-white">Atenção</span> <br />
                            As configurações de Série e Número de nota fiscal eletrônica são de extrema importância. Os intervalos entre os números devem ser sequenciais, caso pule o intervalo é necessário <strong>inutilização da numeração para que não ocorra multas ao fisco</strong>.
                        </div>
                        <ul className="mt-2">
                            <li><strong>Para novas empresas:</strong> Informe a série igual a 1 e o número da próxima nota fiscal igual a 1.</li>
                            <li><strong>Substituição do emissor antigo:</strong> Informe a mesma série que está sendo utilizado no emissor antigo e informe o número que deve ser emitido a próxima nota fiscal.</li>
                            <li><strong>Continuação do emissor antigo:</strong> Será necessário começar uma nova sequência em outra série, deste modo informe uma série diferente entre 2 a 99 e o número da próxima nota fiscal igual a 1.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Dialog onClose={(e) => this.setState({ isShowingRPSModal: false })} fullWidth maxWidth={"xs"} open={this.state.isShowingRPSModal}>
                <MuiDialogTitle className='text-center'>
                    Configurar RPS
                </MuiDialogTitle>
                <MuiDialogContent>
                    <div className="col-lg-12">
                        <AlertWarning message="Você deve acessar o site da sua prefeitura e verificar qual é a sequência atual do RPS para evitar duplicidade de RPS o que impossibilita a emissão da nota." />
                        <label className="field-label">Série do RPS</label>
                        <input type="text" onChange={(e) => this.setState({ serie_rps: e.target.value.toUpperCase() })} value={this.state.serie_rps} className="form-control" />
                        <label className="field-label">Número do <strong>PRÓXIMO RPS</strong></label>
                        <input type="number" onChange={(e) => this.setState({ nfse_rps_numero: e.target.value })} value={this.state.nfse_rps_numero} className="form-control" />
                    </div>
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className='btn-group m-3'>
                        <button onClick={(e) => this.setState({ isShowingRPSModal: false })} className='btn btn-secondary'>Fechar</button>
                        <button onClick={this.updateRPS} className='btn btn-success'>Salvar configurações</button>
                    </div>
                </MuiDialogActions>
            </Dialog>
            <Dialog onClose={(e) => this.setState({ isShowingSerieNFCEModal: false })} fullWidth maxWidth={"xs"} open={this.state.isShowingSerieNFCEModal}>
                <MuiDialogTitle className='text-center'>
                    Configurar Séries NFC-e
                </MuiDialogTitle>
                <MuiDialogContent>
                    <div className="col-lg-12">
                        <div className='alert alert-warning'>
                            <span className="badge text-bg-warning text-white">Atenção</span> <br />
                            Você deve acessar o site da sua prefeitura e verificar qual é a sequência atual da série para evitar duplicidade o que impossibilita a emissão da nota. <p />
                        </div>
                        <label className="field-label">Número de Série</label>
                        <input type="text" onChange={(e) => this.setState({ nfce_serie: e.target.value.toUpperCase() })} value={this.state.nfce_serie} className="form-control" />
                        <label className="field-label">Número da <strong>PRÓXIMA NOTA FISCAL</strong></label>
                        <input type="number" onChange={(e) => this.setState({ nfce_numero: e.target.value })} value={this.state.nfce_numero} className="form-control" />
                    </div>
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className='btn-group m-3'>
                        <button onClick={(e) => this.setState({ isShowingSerieNFCEModal: false })} className='btn btn-secondary'>Fechar</button>
                        <button onClick={this.updateRPSNFCE} className='btn btn-success'>Salvar configurações</button>
                    </div>
                </MuiDialogActions>
            </Dialog>
            <Dialog onClose={(e) => this.setState({ isShowingCSCModal: false })} fullWidth maxWidth={"sm"} open={this.state.isShowingCSCModal}>
                <MuiDialogTitle className='text-center'>
                    Código de Segurança do Contribuinte (CSC)
                </MuiDialogTitle>
                <MuiDialogContent>
                    <div className="col-lg-12">
                        Encontre as informações de cadastramento para sua NFC-e
                        Para realizar a emissão da sua Nota Fiscal de Consumidor (NFC-e)
                        é necessário realizar o credenciamento do Código de Segurança do Contribuinte (CSC), conforme
                        o estado correspondente do CNPJ emissor.
                        <p />
                        Ao realizar o credenciamento irá obter os seguintes tokens:
                        <p />

                        ID do Código CSC:<br />
                        Código CSC:
                        <p />

                        Abaixo encontra-se a relação de todos os estados que oferecem o credenciamento do CSC: <p />

                        <ul>
                            <li>Acre (AC) - <a href='http://webmania.me/2nBIDdK' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Alagoas (AL) - <a href='http://webmania.me/2owrHoe' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Amapá (AP) - <a href='http://webmania.me/2p4NGQW' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Amazonas (AM) - <a href='http://webmania.me/2nBL1kG' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Bahia (BA) - <a href='http://webmania.me/2o3WRQK' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Ceará (CE) - <a href='https://webmania.me/35q9E5G' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Distrito Federal (DF) - <a href='http://webmania.me/2p4PMAm' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Espírito Santo (ES) - <a href='http://webmania.me/2p4z2ZU' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Goiás (GO) - <a href='http://webmania.me/2p4EcVF' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Maranhão (MA) - <a href='http://webmania.me/2p2DStz' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Mato Grosso (MT) - <a href='http://webmania.me/2nBLVO6' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Mato Grosso do Sul (MS) - <a href='http://webmania.me/2onbcZm' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Minas Gerais (MG) - <a href='https://webmania.me/3M0YKHP' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Pará (PA) - <a href='http://webmania.me/2pqC2PA' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Paraíba (PB) - <a href='http://webmania.me/2o3RW22' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Paraná (PR) - <a href='http://webmania.me/2owweXL' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Pernambuco (PE) - <a href='http://webmania.me/2p2DHyf' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Piauí (PI) - <a href='http://webmania.me/2nBJ2N6' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Rio de Janeiro (RJ) - <a href='http://webmania.me/2o48Rl1' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Rio Grande do Norte (RN) - <a href='http://webmania.me/2nBHG4N' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Rio Grande do Sul (RS) - <a href='http://webmania.me/2opuEGE' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Rondônia (RO) - <a href='http://webmania.me/2opu2Rm' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Roraima (RR) - <a href='http://webmania.me/2o1BWg1' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Santa Catarina (SC) - <a href='https://webmania.me/3sXDp9y' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>São Paulo (SP - Obrigatório possuir SAT) - <a href='http://webmania.me/2oViYge' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Sergipe (SE) - <a href='http://webmania.me/2opxhrP' target="_blank"><strong>Acessar site</strong></a></li>
                            <li>Tocantins (TO) - <a href='http://webmania.me/2o1DHK9' target="_blank"><strong>Acessar site</strong></a></li>
                        </ul>
                        <strong>Os tokens deverão ser cadastrados no painel para poder emitir a NFC-e.</strong>
                    </div>
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className='btn-group m-3'>
                        <button onClick={(e) => this.setState({ isShowingCSCModal: false })} className='btn btn-secondary'>Fechar</button>
                    </div>
                </MuiDialogActions>
            </Dialog>
            <Dialog onClose={this.hideAnonymouIssuer} fullWidth maxWidth='sm' open={this.state.isShowAnonymouIssuer}>
                <MuiDialogTitle className='text-center'>
                    Configurar tomador anônimo
                </MuiDialogTitle>
                <MuiDialogContent>
                    <div className='row'>
                        <div className="col-lg-6">
                            <label>Nome</label>
                            <input type="text" onChange={(e) => this.setState({ tomador_anonimo_nome_temp: e.target.value })} value={this.state.tomador_anonimo_nome_temp} className="form-control" />
                        </div>
                        <div className="col-lg-6">
                            <label>Documento</label>
                            <input type="text" onChange={(e) => this.setState({ tomador_anonimo_documento_temp: e.target.value })} value={this.state.tomador_anonimo_documento_temp} className="form-control" />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group'>
                            <h5 className='my-3'>Endereço</h5>
                        </div>
                        <div className="col-lg-3">
                            <label>CEP</label>
                            <NumberFormat autoComplete='off' className="form-control" value={this.state.tomador_anonimo_cep_temp} onChange={(e) => this.fetchCEPAnonymous(e.target.value)} format="#####-###" />
                        </div>
                        <div className="col-lg-6">
                            <label>Logradouro</label>
                            <input type="text" onChange={(e) => this.setState({ tomador_anonimo_logradouro_temp: e.target.value })} value={this.state.tomador_anonimo_logradouro_temp} className="form-control" />
                        </div>
                        <div className="col-lg-3">
                            <label>Número</label>
                            <input type="text" onChange={(e) => this.setState({ tomador_anonimo_numero_temp: e.target.value })} value={this.state.tomador_anonimo_numero_temp} className="form-control" />
                        </div>
                        <div className="col-lg-6">
                            <label>Bairro</label>
                            <input type="text" onChange={(e) => this.setState({ tomador_anonimo_bairro_temp: e.target.value })} value={this.state.tomador_anonimo_bairro_temp} className="form-control" />
                        </div>
                        <div className="col-lg-3">
                            <label>Cidade</label>
                            <input type="text" onChange={(e) => this.setState({ tomador_anonimo_cidade_temp: e.target.value })} value={this.state.tomador_anonimo_cidade_temp} className="form-control" />
                        </div>
                        <div className="col-lg-3">
                            <label>Estado</label>
                            <input type="text" onChange={(e) => this.setState({ tomador_anonimo_estado_temp: e.target.value })} value={this.state.tomador_anonimo_estado_temp} className="form-control" />
                        </div>
                        <div className="col-lg-12">
                            <label>Complemento</label>
                            <input type="text" onChange={(e) => this.setState({ tomador_anonimo_complemento_temp: e.target.value })} value={this.state.tomador_anonimo_complemento_temp} className="form-control" />
                        </div>
                    </div>
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className='btn-group m-3'>
                        <button onClick={this.savaAnonymoyIssuer} className='btn btn-success'>Salvar</button>
                    </div>
                </MuiDialogActions>
            </Dialog>
            <button type="button" onClick={this.update} className="btn btn-success">Atualizar informações</button>
        </>
    }
}

export { ConfigurarNotaNovo, ConfigurarNotaNovoBody };