import React from 'react';
import { NavBar } from '../../components/navbar'
import { getEstacionamento } from '../../config/auth'
import { EmptyContent } from '../../components/common/commons'
import { ListarMensalistas } from '../mensalista/clientes/index'
import Moment from 'moment';
import { getAllMonthlyByParkId } from '../../store/collections/mensalistaWorker';

class MonthlySearch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            random: Math.random(),
            status: null,
            monthlies: [],
            monthliesAux: [],
            showEmptyContainer: false,
            title: "Aguarde estamos buscando todos os mensalistas..."
        }
    }

    componentDidMount = async () => {
        await this.loadAllMonthlies()
        var status = this.queryStatus()
        if (status !== null) {
            this.searchStatus(status)
        } else {
            this.searchStatus("todos")
        }
    }

    queryStatus = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        return params.get('status');
    }

    searchStatus = (status) => {
        this.setState({ status: status }, () => {
            this.findByStatus()
        });
    }

    isExpired = (date) => {
        if (date) {
            var now = new Date()
            var dateContract = Moment(date.seconds * 1000).toDate()
            return !this.today(date) && (now > dateContract)
        }
        return true
    }

    today = (date) => {
        if (date) {
            var todayServer = Moment(new Date()).format("DD/MM/YYYY")
            var todayMonthly = Moment(date.seconds * 1000).toDate()
            return todayServer === todayMonthly
        }
        return true
    }

    showEmptyContainer = () => {
        this.setState({ showEmptyContainer: true })
        this.setState({ title: "Não encontramos nenhum mensalista com este filtro." })
        this.setState({ random: Math.random() })
    }

    findByStatus = () => {
        var monthlies = this.state.monthliesAux
        if (monthlies.length === 0) {
            this.showEmptyContainer()
        } else {
            if (this.state.status === "todos") {
                this.setState({ monthlies: monthlies }, () => {
                    this.updateContainer()
                })
                return
            }
            if (this.state.status === "desativados") {
                var desativados = monthlies.filter(e => e.status === "inativo")
                this.setState({ monthlies: desativados }, () => {
                    this.updateContainer()
                })
                return
            }
            var allValides = monthlies.filter(e => !this.isExpired(e.plano.validadeContrato))
            if (this.state.status === "pagos") {
                var payed = allValides.filter(e => !this.isExpired(e.plano.dataFatura))
                this.setState({ monthlies: payed }, () => {
                    this.updateContainer()
                })
            } else if (this.state.status === "vence-hoje") {
                var today = allValides.filter(e => this.today(e.plano.dataFatura))
                this.setState({ monthlies: today }, () => {
                    this.updateContainer()
                })
            } else if (this.state.status === "vencidos") {
                var expirateds = allValides.filter(e => this.isExpired(e.plano.dataFatura))
                this.setState({ monthlies: expirateds }, () => {
                    this.updateContainer()
                })
            } else if (this.state.status === "expirados") {
                var contractExpirated = monthlies.filter(e => this.isExpired(e.plano.validadeContrato))
                this.setState({ monthlies: contractExpirated }, () => {
                    this.updateContainer()
                })
            }
        }
    }

    updateContainer = () => {
        if (this.state.monthlies.length > 0) {
            this.setState({ showEmptyContainer: false })
            this.setState({ random: Math.random() })
        } else {
            this.showEmptyContainer()
        }
    }

    async loadAllMonthlies() {
        this.setState({ loading: true })
        const items = await getAllMonthlyByParkId({ estacionamentoId: this.state.estacionamentoId, withCharges: true })
        this.setState({ monthlies: items })
        this.setState({ monthliesAux: items })
        this.setState({ loading: false })
    }

    applyFilter = () => {
        this.setState({ random: Math.random() })
    }

    render() {
        return (
            <NavBar>
                <div className='row'>
                    <div className="col-lg-3">
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                Selecione o seu filtro
                            </div>
                            <div className="card-body">
                                <div className="btn-group-vertical w-100">
                                    <button type="button" className="btn btn-secondary" onClick={(e) => this.searchStatus("todos")}>Buscar todos</button>
                                    <button type="button" className="btn btn-secondary" onClick={(e) => this.searchStatus("pagos")}>Mensalistas em dia</button>
                                    <button type="button" className="btn btn-secondary" onClick={(e) => this.searchStatus("vence-hoje")}>Vencem hoje</button>
                                    <button type="button" className="btn btn-secondary" onClick={(e) => this.searchStatus("vencidos")}>Mensalistas atrasadas</button>
                                    <button type="button" className="btn btn-secondary" onClick={(e) => this.searchStatus("expirados")}>Contratos expirados</button>
                                    <button type="button" className="btn btn-secondary" onClick={(e) => this.searchStatus("desativados")}>Desativados</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.loading &&
                        <div className='col-lg-9'>
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Mensalistas
                                </div>
                                <div className="card-body py-3">
                                    <EmptyContent text="Aguarde estamos buscando todos os mensalistas..." />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        !this.state.loading &&
                        <div className="col-lg-9">
                            <ListarMensalistas key={this.state.random} monthlies={this.state.monthlies} />
                        </div>
                    }
                </div>
            </NavBar>
        )
    }
}

export { MonthlySearch };