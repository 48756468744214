import React from 'react'
import { getParameterByParkId } from "../../store/collections/parameterWorker"
import { PrinterHeaderComponent, PrinterCaixaHeaderComponent, BodyTicketInComponent, BodyMonthlyChargeComponent, PrinterPaymentsComponent, PrinterInternalReviewComponent, PrinterProductsComponent, PrinterFooterComponent, PrinterQRCodeComponent, MessageInTicketComponent, CashierReportTotalsDocument, CashierReportFormasDePagamentosCaixas, CashierReportListagemMensalistasCaixas, CashierReportTabelasUtilizadasCaixas, CashierReportConveniosCaixas, CashierReportDescontosCaixas, CashierReportCaixasAnalisados, CashierReportMovimentacoesCaixas, CashierReportRetiradasCaixas, CashierReportCancelamentosCaixas } from "./printer-manager"
class PrinterTicketComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ticket: props.ticket,
            park: props.park,
        }
        this.load()
    }

    load = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.park.id })
        this.setState({ parameters: parameters })
    }

    render() {
        return (<>
            {
                this.state.parameters &&
                <div className='text-center'>
                    <div style={{ fontFamily: 'Arial', fontSize: '10px', fontWeight: 'bold', maxWidth: '80%', margin: '0 auto', color: 'black' }}>
                        <PrinterHeaderComponent parameters={this.state.parameters} park={this.state.park} />
                        <BodyTicketInComponent parameters={this.state.parameters} ticket={this.state.ticket} />
                        <PrinterInternalReviewComponent parameters={this.state.parameters} ticket={this.state.ticket} />
                        <PrinterProductsComponent parameters={this.state.parameters} ticket={this.state.ticket} />
                        <PrinterPaymentsComponent ticket={this.state.ticket} />
                        <PrinterQRCodeComponent ticket={this.state.ticket} parameters={this.state.parameters} />
                        <MessageInTicketComponent parameters={this.state.parameters} position={"fim"} />
                        <PrinterFooterComponent park={this.state.park} />
                    </div>
                </div>
            }
        </>
        )
    }
}

class PrinterMonthlyChargeComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            charge: props.charge,
            park: props.park
        }
        this.load()
    }

    load = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.park.id })
        this.setState({ parameters: parameters })
    }

    render() {
        return (<>
            {
                this.state.parameters &&
                <div className='text-center'>
                    <div style={{ fontFamily: 'Arial', fontSize: '10px', fontWeight: 'bold', maxWidth: '80%', margin: '0 auto', color: 'black' }}>
                        <PrinterHeaderComponent parameters={this.state.parameters} park={this.state.park} />
                        <BodyMonthlyChargeComponent parameters={this.state.parameters} charge={this.state.charge} />
                        <PrinterFooterComponent park={this.state.park} />
                    </div>
                </div>
            }
        </>
        )
    }
}

class PrinterCashierComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            park: props.park,
            resultado: props.resultado,
            impressaoCaixaTotais: props.impressaoCaixaTotais,
            impressaoListagemMensalistasCaixas: props.impressaoListagemMensalistasCaixas,
            impressaoTabelasUtilizadasCaixas: props.impressaoTabelasUtilizadasCaixas,
            impressaoConveniosCaixas: props.impressaoConveniosCaixas,
            impressaoDescontosCaixas: props.impressaoDescontosCaixas,
            impressaoMovimentacoesCaixas: props.impressaoMovimentacoesCaixas,
            impressaoRetiradasCaixas: props.impressaoRetiradasCaixas,
            impressaoCancelamentosCaixas: props.impressaoCancelamentosCaixas
        }
        this.load()
    }

    load = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.park.id })
        this.setState({ parameters: parameters })
    }

    render() {
        return (<>
            {
                this.state.parameters &&
                <div className='text-center'>
                    <div style={{ fontFamily: 'Arial', fontSize: '11px', fontWeight: 'bold', maxWidth: '85%', margin: '0 auto', color: 'black' }}>
                        <PrinterCaixaHeaderComponent parameters={this.state.parameters} park={this.state.park} resultado={this.state.resultado}/>
                        <CashierReportCaixasAnalisados resultado={this.state.resultado} />
                        {
                            this.state.impressaoCaixaTotais && <>
                                <CashierReportTotalsDocument resultado={this.state.resultado} />
                                <CashierReportFormasDePagamentosCaixas resultado={this.state.resultado} />
                            </>
                        }
                        {
                            this.state.impressaoTabelasUtilizadasCaixas && <CashierReportTabelasUtilizadasCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoListagemMensalistasCaixas && <CashierReportListagemMensalistasCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoMovimentacoesCaixas && <CashierReportMovimentacoesCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoRetiradasCaixas && <CashierReportRetiradasCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoConveniosCaixas && <CashierReportConveniosCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoDescontosCaixas && <CashierReportDescontosCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoCancelamentosCaixas && <CashierReportCancelamentosCaixas resultado={this.state.resultado} />
                        }
                        <PrinterFooterComponent park={this.state.park} />
                    </div>
                </div>
            }
        </>
        )
    }
}

export { PrinterTicketComponent, PrinterMonthlyChargeComponent, PrinterCashierComponent }