import React from 'react';
import { getPerfil, isAdministrador } from '../../config/auth'
import { sendScreenView } from '../../shared/analytics'

import { Link } from 'react-router-dom';
import './navbar.css';
import 'firebase/auth';
import 'firebase/storage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';

export const NavBarMenuEvents = props => {
    const [perfil] = React.useState(getPerfil());
    const [administrativo] = React.useState(isAdministrador());
    const [menuOpen, setMenuOpen] = React.useState(false);

    const [acessoCadastrarEventos] = React.useState((perfil?.createEvent ?? true))
    const [acessoPagamentos] = React.useState(administrativo)
    // || acessoCadastrarEventos

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    return (
        <>
            {
                acessoPagamentos &&
                <List>
                    <ListItem key={'Eventos'} button onClick={toggleMenu}>
                        <ListItemIcon> <TheaterComedyIcon /></ListItemIcon>
                        <ListItemText primary='Eventos' />
                        {/* <span className="badge text-bg-success">Novidades</span> */}
                    </ListItem>
                    {
                        menuOpen && <>
                            {
                                acessoCadastrarEventos &&
                                <Link to='/cadastrar/evento'>
                                    <ListItem button key='Evento' onClick={(e) => sendScreenView("Cadastrar", "Evento")}>
                                        <ListItemText primary='Evento' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                        </>
                    }
                </List>
            }
        </>
    );
}