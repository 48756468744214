import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { generateUUIDV7, isValidCPF, isValidDocument, onlyNumbers, toastDismissLoading, toastLoading, toastSuccess, toastWarning } from '../../shared/utils';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuarioCompact } from '../../config/auth';
import { Loading } from '../../components/common/commons';
import moment from 'moment';
import { addNewNote, getAllGeneratedNotesInCurrentMonth } from '../../store/collections/newNotesWorker';
import { sendClickButton, sendLog, sendLogByUser } from '../../shared/analytics';
import NumberFormat from 'react-number-format';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import CurrencyInput from '../../components/currencyInput/CurrencyInput';
import { searchCEP } from '../../shared/cepWorker';
import { TextField } from '@material-ui/core';
import { getParameter } from '../../store/collections/parameterWorker';
import { getLicencaByParkId } from '../../store/collections/licencaWorker';
import { getFirstHighlightPlan, getLicencePlanById } from '../../store/collections/planosLicencaWorker';
import { ItemPlan } from '../licenca/my-licence';

class OperationSingleNoteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clienteId: getCliente()?.id,
            revendaId: getRevendaIdByPark(),
            estacionamentoId: getEstacionamento()?.id,
            modalState: "loading",
            isLoading: false,
            tomador: false,
            cep: "",
            numero: "",
            nome: "",
            tipoDocumento: "CPF",
            documento: null,
            valor: 0.0,
            data: moment(),
            message: null
        }
    }

    componentDidMount = () => {
        this.load()
    }

    load = async () => {
        this.setState({ modalState: "loading" })
        const canAdd = await this.verifyQuantityByPlan()
        if (canAdd) {
            var parameter = await getParameter()
            this.setState({ modalState: parameter.habilitarEmissaoNotaV2 === true ? "enabled" : "disabled" })
            this.setState({ habilitarEmissaoNotaMostrarCampoNome: parameter.habilitarEmissaoNotaMostrarCampoNome })
            this.setState({ habilitarEmissaoNotaMostrarCampoEndereco: parameter.habilitarEmissaoNotaMostrarCampoEndereco })
            this.setState({ habilitarEmissaoNotaMostrarTomador: parameter.habilitarEmissaoNotaMostrarTomador })
            this.setState({ habilitarEmissaoNotaCampoEnderecoObrigatorio: parameter.habilitarEmissaoNotaCampoEnderecoObrigatorio })
            this.setState({ habilitarEmissaoNotaSelecionarDataServico: parameter.habilitarEmissaoNotaSelecionarDataServico })
        }
    }

    verifyQuantityByPlan = async () => {
        return true
        const licence = await getLicencaByParkId({ parkId: this.state.estacionamentoId })
        if (licence.plan) {
            const plan = await getLicencePlanById({ id: licence.plan.id })
            if (plan) {
                const allNotes = await getAllGeneratedNotesInCurrentMonth({ parkId: this.state.estacionamentoId })
                const notesByPlan = parseInt(plan.quantidadeNotasMes ?? 0)
                if (allNotes.length >= notesByPlan) {
                    const highlightPlan = await getFirstHighlightPlan()
                    const moreThanCurrent = parseInt(highlightPlan.quantidadeNotasMes ?? 0)
                    if (moreThanCurrent > notesByPlan) {
                        this.setState({ highlightPlan: highlightPlan })
                    }
                    this.setState({ messageMaximumUsersModal: `Você atingiu o limite máximo de ${notesByPlan} notas emitidas no mês no seu plano atual, mas você pode assinar um novo plano.` })
                    const user = getUsuarioCompact()
                    sendLog("Usuários", `O usuário ${user.nome} visualizou o modal de limite máximo de notas: ${notesByPlan} permitidos e ${allNotes.length} emitidas.`)
                    this.setState({ modalState: "change-plan" })
                    return false
                }
                return true
            }
        } else {
            const highlightPlan = await getFirstHighlightPlan()
            this.setState({ highlightPlan: highlightPlan })
            this.setState({ messageMaximumUsersModal: `É preciso ter um plano ativo para emitir notas fiscais.` })
            const user = getUsuarioCompact()
            sendLog("Usuários", `O usuário ${user.nome} visualizou o modal de contratação de planos para emissão de notas.`)
            this.setState({ modalState: "change-plan" })
            return false
        }
    }

    selectPlan = (plan) => {
        const user = getUsuarioCompact()
        sendClickButton("Usuários", `Usuário ${user.nome} tocou no botão para alterar plano por limite máximo de usuário.`)
        window.location.href = `/licenca/minha-licenca?type=plan&id=${plan.id}`
    }

    closeMaximumUserModal = () => {
        const user = getUsuarioCompact()
        sendClickButton("Usuários", `Usuário ${user.nome} fechou o modal de limite notas emitidas.`)
        this.onClose()
    }

    onConfirm = async () => {
        this.setState({ message: null })
        if (!this.state.tomador) {
            if (!isValidDocument(this.state.documento)) {
                this.setState({ message: "Informe um documento válido." })
                return
            }
            if (this.state.tipoDocumento === "CPF" && this.state.habilitarEmissaoNotaCampoEnderecoObrigatorio) {
                if (!this.state.cep) {
                    this.setState({ message: "Informe o CEP válido para o tomador." })
                    return
                }
                if (!this.state.numero) {
                    this.setState({ message: "Informe o número do endereço do tomador." })
                    return
                }
                if (!this.state.logradouro) {
                    this.setState({ message: "Informe o Logradouro para o tomador." })
                    return
                }
                if (!this.state.bairro) {
                    this.setState({ message: "Informe o Bairro para o tomador." })
                    return
                }
            }
        }
        if (this.state.valor <= 0.0) {
            this.setState({ message: "Informe o valor da nota." })
            return
        }
        if (this.state.data.isAfter(new Date())) {
            this.setState({ message: "A data do serviço não pode ser maior que a data atual." })
            return
        }
        toastLoading("Gerando nota avulsa...")
        const user = getUsuarioCompact()
        const client = getCliente()
        var data = {
            revendaId: this.state.revendaId,
            clienteId: client.id,
            estacionamentoId: this.state.estacionamentoId,
            identifier: generateUUIDV7(),
            type: "customizado",
            createdBy: user,
            createdAt: new Date(),
            status: [{
                createdAt: new Date(),
                status: "NOTE_PROCESSING",
                createdBy: user
            }],
            currentStatus: "NOTE_PROCESSING",
            valor: parseFloat(this.state.valor),
            issuer: !this.state.tomador
        }
        if (!this.state.tomador) {
            data.document = onlyNumbers(this.state.documento)
            data.name = this.state.nome?.substring(0, 30)
            data.cep = this.state.cep
            data.numero = this.state.numero
            if (this.state.isEditingLogradouro) {
                data.logradouro = this.state.logradouro
            }
            if (this.state.isEditingBairro) {
                data.bairro = this.state.bairro
            }
        }
        if (this.state.cep) {
            this.fetchCEP(this.state.cep)
        }
        if (!this.state.data.isSame(moment())) {
            data.dataCompetencia = this.state.data.toDate()
        }
        this.setState({ isLoading: true })
        sendLogByUser("Operação", "Gerou uma nota avulsa", data)
        await addNewNote({ data: data })
        toastDismissLoading()
        toastSuccess("Nota avulsa enviada para processamento com sucesso.")

        this.setState({ isLoading: false })
        this.setState({ valor: 0.0 })
        this.setState({ messag: null })
        this.setState({ nome: "" })
        this.setState({ documento: "" })
        this.onSave()
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    onSave = () => {
        if (this.props.onSave) {
            this.props.onSave()
        }
    }

    fetchCEP = async (value) => {
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ isEditingLogradouro: false })
            this.setState({ isEditingBairro: false })
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ uf: result.uf }, () => {
                this.updateAddress()
            })
        } else {
            this.setState({ cep: null })
            this.setState({ allAddress: "" })
        }
    }

    handleDataNotaFiscalChange = (newValue) => {
        this.setState({ data: newValue })
    }

    updateNumber = (e) => {
        this.setState({ numero: e.target.value }, () => {
            this.updateAddress()
        })
    }

    updateLogradouro = (e) => {
        this.setState({ isEditingLogradouro: true })
        this.setState({ logradouro: e.target.value }, () => {
            this.updateAddress()
        })
    }

    updateBairro = (e) => {
        this.setState({ isEditingBairro: true })
        this.setState({ bairro: e.target.value }, () => {
            this.updateAddress()
        })
    }

    updateAddress = () => {
        if (this.state.cep) {
            this.setState({ allAddress: `${this.state.logradouro}, ${this.state.numero ?? ""} - ${this.state.bairro}, ${this.state.uf}` })
        } else {
            this.setState({ allAddress: "" })
        }
    }

    updateValor = (event, maskedvalue, floatvalue) => {
        this.setState({ valor: floatvalue })
    }

    render() {
        return (
            <>
                {
                    this.state.modalState === "loading" &&
                    <MuiDialogContent>
                        <Loading />
                    </MuiDialogContent>
                }
                {
                    this.state.modalState === "enabled" && <>
                        <MuiDialogTitle className='text-center'>
                            Emitir nota avulsa
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            {
                                this.state.message &&
                                <div className='col-lg-12'>
                                    <div className='alert alert-warning'>
                                        <strong>Atenção: </strong> {this.state.message}
                                    </div>
                                </div>
                            }
                            {
                                this.state.habilitarEmissaoNotaMostrarTomador &&
                                <div className="col-lg-12">
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="tomador" checked={this.state.tomador} onChange={(e) => this.setState({ tomador: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="tomador">
                                            Não informar os dados do cliente
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                !this.state.tomador &&
                                <>
                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <label>Tipo documento</label>
                                            <div className="input-group mb-3">
                                                <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} required>
                                                    <option value='CPF'>CPF</option>
                                                    <option value='CNPJ'>CNPJ</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Número documento</label>
                                            <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                        </div>
                                    </div>
                                    {
                                        this.state.habilitarEmissaoNotaMostrarCampoNome &&
                                        <div className="col-lg-12">
                                            {this.state.tipoDocumento ? <label>Nome do cliente</label> : <label>Razão social da empresa</label>}
                                            <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" required />
                                        </div>
                                    }
                                </>
                            }
                            {
                                isValidCPF(this.state.documento) && this.state.tipoDocumento == "CPF" && this.state.habilitarEmissaoNotaMostrarCampoEndereco &&
                                <div className='alert alert-warning mt-3'>
                                    <div className='row'>
                                        <div className="col-lg-12 mt-2">
                                            <h6>{this.state.habilitarEmissaoNotaCampoEnderecoObrigatorio === true ? "*Obrigatório Pessoa Física" : "Informar o cep é opcional"}</h6>
                                        </div>
                                        <div className="col-lg-8">
                                            <label>CEP</label>
                                            <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                        </div>
                                        <div className="col-lg-4">
                                            <label>Número</label>
                                            <input type="text" onChange={(e) => this.updateNumber(e)} value={this.state.numero} className="form-control" />
                                        </div>
                                        {
                                            (this.state.logradouro == "" || this.state.isEditingLogradouro) &&
                                            <div className="col-lg-6">
                                                <label>Logradouro</label>
                                                <input type="text" onChange={(e) => this.updateLogradouro(e)} value={this.state.logradouro} className="form-control" />
                                            </div>
                                        }
                                        {
                                            (this.state.bairro == "" || this.state.isEditingBairro) &&
                                            <div className="col-lg-6">
                                                <label>Bairro</label>
                                                <input type="text" onChange={(e) => this.updateBairro(e)} value={this.state.bairro} className="form-control" />
                                            </div>
                                        }
                                    </div>
                                    <div className="col-lg-12 mt-2">
                                        <h6>{this.state.allAddress}</h6>
                                    </div>
                                </div>
                            }
                            <div className="col-lg-12">
                                <label>Valor</label>
                                <CurrencyInput value={this.state.valor} prefix="R$" className='form-control' onChangeEvent={this.updateValor} />
                            </div>
                            {
                                this.state.habilitarEmissaoNotaSelecionarDataServico &&
                                <div className="col-lg-12">
                                    <label>Data do serviço</label>
                                    <MobileDatePicker
                                        label="-"
                                        inputFormat="DD/MM/yyyy"
                                        value={this.state.data}
                                        onChange={this.handleDataNotaFiscalChange}
                                        renderInput={(params) => <TextField {...params} size="small" className='form-control w-100' />}
                                    />
                                </div>
                            }
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="btn-group m-3">
                                <button onClick={(e) => this.onClose()} className='btn btn-secondary'>Fechar</button>
                                <button onClick={(e) => this.onConfirm()} className='btn btn-success'>Confirmar</button>
                            </div>
                        </MuiDialogActions>
                    </>
                }
                {
                    this.state.modalState === "disabled" &&
                    <>
                        <MuiDialogTitle className='text-center'>
                            Emitir nota avulsa
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='alert alert-success'>
                                <span className="badge text-bg-success">Novidade</span> <br />
                                Agora é possível emitir nota fiscal avulsa direto no administrativo. <br />
                                <a href='/nota-fiscal/configurar' onClick={(e) => this.enableNewNote()} className='btn btn-success mt-2'>Habilitar emissão de notas</a>
                            </div>
                        </MuiDialogContent>
                    </>
                }
                {
                    this.state.modalState === "change-plan" &&
                    <>
                        <MuiDialogContent>
                            <div className='row text-center mt-3'>
                                <h5>{this.state.messageMaximumUsersModal}</h5>
                                {
                                    this.state.highlightPlan &&
                                    <div className='col-lg-12 mt-3'>
                                        <ItemPlan key={this.state.highlightPlan.id} plan={this.state.highlightPlan} onSelected={this.selectPlan} />
                                    </div>
                                }
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="btn-group m-3">
                                <button onClick={this.closeMaximumUserModal} className="btn btn-secondary">Fechar</button>
                            </div>
                        </MuiDialogActions>
                    </>
                }
            </>
        )
    }
}

export { OperationSingleNoteModal }