import React from 'react'

import Tooltip from '@mui/material/Tooltip';
import { getCliente, getEstacionamento, getRevenda } from '../../../config/auth'
import { Loading } from '../../../components/common/commons'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Moment from 'moment';
import { addAccessCard, getAllAccessCardsByReferenceId, removeAccessCard, getAccessCardByParkIdAndNumber } from '../../../store/collections/accessCardWorker';
import { getAllVeiculosByMonthlyIdAndAccessNumber } from '../../../store/collections/veiculosWorker';
import { copyLinkAndShare, isNullOrEmpty, toastDismissLoading, toastError, toastLoading, toastSuccess, toastWarning } from '../../../shared/utils';
import { getMonthlyById } from '../../../store/collections/mensalistaWorker';
import { getAccreditedById } from '../../../store/collections/accreditedWorker';
import { AlertSuccess } from '../../../shared/alert-manager';
import { sendLogByUser } from '../../../shared/analytics';
import { fetchFunctionCall } from '../../../shared/function-request';

class RegisterAccessCardModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            referenceId: props.referenceId,
            showModal: props.showModal,
            state: "loading",
            type: "",
            cardNumber: "",
            allAccessCard: [],
            type: props.type
        }
    }

    componentDidMount = async () => {
        this.load()
    }

    load = async () => {
        this.setState({ showModal: true })
        this.setState({ state: "loading" })
        await this.loadAccessCards()
        await this.loadReference()
        this.setState({ state: "fill" })
    }

    loadAccessCards = async () => {
        var allAccessCard = await getAllAccessCardsByReferenceId({ referenceId: this.state.referenceId, estacionamentoId: this.state.estacionamentoId })
        this.setState({ allAccessCard: allAccessCard })
    }

    loadReference = async () => {
        const monthly = await getMonthlyById(this.state.referenceId)
        if (monthly) {
            this.setState({ type: "monthly" })
            this.setState({ cellphone: monthly.celular })
            this.setState({ name: monthly.nome })
        } else {
            const accredited = await getAccreditedById({ id: this.state.referenceId })
            if (accredited) {
                this.setState({ type: "accredited" })
                this.setState({ cellphone: accredited.celular })
                this.setState({ name: accredited.nome })
            }
        }
    }

    hideModal = () => {
        this.setState({ showModal: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    onRemove = async (item) => {
        var confirm = window.confirm(`Deseja mesmo remover este cartão de acesso?`);
        if (confirm) {
            toastLoading("Removendo...")
            if (this.state.type === "monthly") {
                const vehicles = await getAllVeiculosByMonthlyIdAndAccessNumber({ mensalistaId: this.state.referenceId, accessCard: item.cardNumber })
                if (vehicles.length > 0) {
                    toastDismissLoading()
                    toastWarning("Existe um veículo associado a este cartão.")
                    return
                }
            }
            await removeAccessCard({ id: item.id })
            if (this.props.onCardUpdated) {
                this.props.onCardUpdated()
            }
            toastDismissLoading()
            this.load()
        }
    }

    generateAccessCard = async () => {
        const number = Math.floor(Math.random() * 99999999).toString()
        this.setState({ cardNumber: number.padStart(9, "1") })
    }

    save = async () => {
        if (this.state.cardNumber === "") {
            toastWarning("Informe o cartão de acesso.")
            return null
        }
        const number = parseInt(this.state.cardNumber).toString()
        if (number.length < 6) {
            toastWarning("O cartão de acesso deve ter no mínimo 6 números.\nNão consideramos números zeros a esquerda.")
            return null
        }
        this.setState({ state: "loading" })
        const accessCard = await getAccessCardByParkIdAndNumber({ estacionamentoId: this.state.estacionamentoId, accessCard: number })
        if (accessCard) {
            this.setState({ state: "fill" })
            var message = "Já existe um cartão de acesso com este número criado para este estabelecimento."
            if (accessCard.type === "accredited") {
                const accredited = await getAccreditedById({ id: accessCard.referenceId })
                if (accredited) {
                    message = `O credenciado ${accredited.nome} já está associado a este cartão.`
                }
            } else {
                const monthly = await getMonthlyById(accessCard.referenceId)
                if (monthly) {
                    message = `O mensalista ${monthly.nome} já está associado a este cartão.`
                }
            }
            toastWarning(message)
            return
        }
        await addAccessCard({
            clienteId: this.state.clienteId,
            estacionamentoId: this.state.estacionamentoId,
            referenceId: this.state.referenceId,
            type: this.state.type,
            accessCard: this.state.cardNumber
        })
        if (this.props.onCardUpdated) {
            this.props.onCardUpdated()
        }
        this.setState({ cardNumber: "" })
        this.load()
    }

    handleChangeAccessCardNumber = (e) => {
        const value = e.target.value.toUpperCase()
        this.setState({ cardNumber: value })
    }

    copyChargeLink = (item) => {
        const revenda = getRevenda()
        const url = `${revenda.siteTicket}/cartao/visualizar/?id=${item.id}`
        copyLinkAndShare({
            title: item.clienteNome,
            message: "Cartão de acesso",
            url: url,
        })
        const message = `Tocou em copiar o link do cartão de acesso ${item.accessCard}.`
        sendLogByUser("Detalhes Mensalistas", message)
    }

    sendBillByWhatsApp = async (item) => {
        const confirm = window.confirm(`Deseja mesmo enviar o link para o número ${this.state.cellphone}?`);
        if (confirm) {
            const revenda = getRevenda()
            const url = `${revenda.siteTicket}/cartao/visualizar/?id=${item.id}`
            toastLoading("Enviando...")
            const body = {
                template: "ACCESS_CARD",
                clientName: this.state.name,
                url: url,
                id: item.id,
                phone: this.state.cellphone
            }
            const options = {
                method: 'POST',
                body: body
            };
            const response = await fetchFunctionCall("/sendWhatsAppMessageWithTemplate", options)
            toastDismissLoading()
            if (response.result.success) {
                toastSuccess(response.result.message)
            } else {
                toastWarning(response.result.message)
            }
        }
    }

    render() {
        return (
            <>
                <Dialog onClose={this.hideModal} fullWidth maxWidth='sm' open={this.state.showModal}>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "fill" &&
                        <>
                            <MuiDialogTitle className='text-center'>
                                Cadastrar cartão de acesso
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <AlertSuccess tag="Novidade" message="Agora é possível gerar cartões de acesso virtuais para os seus mensalistas ou credenciados." />
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="input-group">
                                            <span className="input-group-btn btn-group">
                                                <Tooltip title="Gerar número de acesso" placement="top">
                                                    <button type="button" onClick={this.generateAccessCard} className="btn btn-success">
                                                        <i className="fas fa-sync-alt" />
                                                    </button>
                                                </Tooltip>
                                            </span>
                                            <input type="text" placeholder='Informar o número do cartão' pattern="[a-zA-Z0-9-]+" value={this.state.cardNumber} onChange={this.handleChangeAccessCardNumber} className="form-control" required />
                                            <span className="input-group-btn btn-group">
                                                <button onClick={this.save} className='btn btn-success'>Adicionar</button>
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        this.state.allAccessCard.length > 0 &&
                                        <div className='col-lg-12 mt-3'>
                                            <h5 className='text-center mt-2'>Cartões associados</h5>
                                            <table className="table">
                                                <tr>
                                                    <th scope="col" align='left' />
                                                    <th scope="col">Cartão</th>
                                                    <th scope="col">Criação</th>
                                                    <th scope="col">Operador</th>
                                                    <th scope="col" align='right' />
                                                </tr>
                                                {
                                                    this.state.allAccessCard.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <div className='btn-group'>
                                                                    <Tooltip title="Copiar link" placement="top">
                                                                        <button onClick={(e) => this.copyChargeLink(item)} className="btn btn-success text-white">
                                                                            <i className="fas fa-share-alt" />
                                                                        </button>
                                                                    </Tooltip>
                                                                    {
                                                                        isNullOrEmpty(this.state.cellphone) ?
                                                                            <Tooltip title="Enviar no WhatsApp" placement="top">
                                                                                <button type="button" onClick={() => { toastError("Este cliente não tem número de celular cadastrado para receber a mensagem via WhatsApp.") }} className="btn btn-danger">
                                                                                    <i className="fa-brands fa-whatsapp" />
                                                                                </button>
                                                                            </Tooltip> :
                                                                            <Tooltip title="Enviar no WhatsApp" placement="top">
                                                                                <button type="button" onClick={() => { this.sendBillByWhatsApp(item) }} className="btn btn-success">
                                                                                    <i className="fa-brands fa-whatsapp" />
                                                                                </button>
                                                                            </Tooltip>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>{item.cardNumber}</td>
                                                            <td>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                            <td>{item.createdBy?.nome}</td>
                                                            <td align='right'>
                                                                <div className='btn-group'>
                                                                    <Tooltip title="Remover" placement="top">
                                                                        <button className="btn btn-danger" onClick={(e) => this.onRemove(item)}>
                                                                            <i className="fas fa-trash" />
                                                                        </button>
                                                                    </Tooltip>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </table>
                                        </div>
                                    }
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={this.hideModal} className='btn btn-secondary'>Fechar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog >
            </>
        )
    }
}

export { RegisterAccessCardModal };