import React from 'react'
import { NavBar } from '../../../components/navbar/index.js'
import { Loading, EmptyContent, HelpButton } from '../../../components/common/commons.js'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getCliente, getEstacionamento, getRevenda, getUsuarioCompact, isAdministrador } from '../../../config/auth.js';
import 'firebase/storage'
import Tooltip from '@mui/material/Tooltip';
import { addCameraLPR, deleteCameraLPR, getAllCamerasLPRByParkId, getCamerasLPRByIdentifierAndParkId, updateCameraLPR } from '../../../store/collections/cameraLPRWorker.js';
import { generateUUIDV7, goToNewWindow, isNullOrEmpty, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastWarning } from '../../../shared/utils.js';
import { AlertInfo, AlertWarning } from '../../../shared/alert-manager.js';
import { sendLogByUser } from '../../../shared/analytics.js';
import { getAllEquipmentsByLPRIdentifierAndParkId } from '../../../store/collections/equipmentWorker.js';
import { TicketAnalyticsDialog } from '../../buscas/ticketSearch.js';
import { ticketsByCameraInWithIdentifierAndParkId, ticketsByCameraOutWithIdentifierAndParkId } from '../../../store/collections/ticketWorker.js';
import { deleteCameraLogById, getCamerasLPRLogsByIdentifierAndParkId } from '../../../store/collections/cameraLPRLogWorker.js';

class CancelaListarCamerasLPR extends React.Component {

    render() {
        return (
            <NavBar>
                <CancelaListarCamerasLPRBody />
            </NavBar>
        )
    }
}

class CancelaListarCamerasLPRBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            revendaId: getRevenda()?.id,
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            name: "",
            model: "Hikvision",
            type: "IN",
            items: [],
            isLoading: true,
            executePaymentWithTag: false,
            registerTicket: false
        }
    }

    componentDidMount = async () => {
        await this.load(true)
    }

    load = async (isLoading) => {
        this.setState({ isRegisterModalOpen: false })
        this.setState({ isLoading: isLoading })
        this.setState({ items: [] })
        var items = await getAllCamerasLPRByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    onEdit = (item) => {
        this.setState({ ...item })
        this.setState({ isRegisterModalOpen: true })
    }

    removeLPR = async () => {
        var confirm = window.confirm(`Confirma mesmo a remoção deste equipamento?`);
        if (confirm) {
            await deleteCameraLPR({ id: this.state.id })
            await this.load()
        }
    }

    openRegisterModal = async () => {
        this.setState({ isRegisterModalOpen: true })
        const identifier = generateUUIDV7()
        this.setState({ identifier: identifier })
        const urlSettings = `https://us-central1-selfparking-prod-26925.cloudfunctions.net/camera/lpr/${identifier}`
        this.setState({ urlSettings: urlSettings })
    }

    save = async () => {
        if (this.state.name === "") {
            toastWarning("Informe um nome para a câmera.")
            return
        }
        toastLoading("Salvando...")
        const data = {
            id: this.state.identifier,
            revendaId: this.state.revendaId,
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            name: this.state.name,
            model: this.state.model,
            identifier: this.state.identifier,
            type: this.state.type,
            urlSettings: this.state.urlSettings,
            executePaymentWithTag: this.state.executePaymentWithTag,
            registerTicket: this.state.registerTicket,
            image: "https://placehold.co/300x300",
        }
        var message = ""
        if (!this.state.id) {
            message = `Cadastrou uma câmera de LPR com o nome ${data.name}.`
            await addCameraLPR({ data: data })
        } else {
            message = `Editou uma câmera de LPR com o nome ${data.name}.`
            await updateCameraLPR({ id: this.state.id, data: data })
        }
        toastDismissLoading()
        this.setState({ isRegisterModalOpen: false })
        sendLogByUser("Câmera LPR", message, data)
        await this.load()
    }

    edit = async (item) => {
        this.setState({ id: item.id })
        this.setState({ name: item.name })
        this.setState({ model: item.model })
        this.setState({ type: item.type })
        this.setState({ identifier: item.identifier })
        this.setState({ urlSettings: item.urlSettings })
        this.setState({ registerTicket: item.registerTicket })
        this.setState({ executePaymentWithTag: item.executePaymentWithTag })
        this.setState({ isRegisterModalOpen: true })
    }

    goToTicket = (item) => {
        const path = `/buscar/ticket?id=${item.currentTicket}`
        goToNewWindow(path)
    }

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Câmeras LPR
                        <div className='btn-group'>
                            <HelpButton />
                            <button type="button" onClick={(e) => { this.openRegisterModal() }} className="btn btn-sm btn-primary">
                                <i className="fas fa-plus mx-2" />
                                Cadastrar
                            </button>
                        </div>
                    </div>
                    {
                        this.state.items.length === 0 && !this.state.isLoading &&
                        <div className="mb-4">
                            <EmptyContent text='Nenhuma câmera de LRP cadastrada' />
                        </div>
                    }
                </div>
                <div className='row'>
                    {
                        this.state.items.map((item, index) =>
                            <div className='col-lg-3 mt-3' key={index}>
                                <CancelaCameraLPRPreview
                                    onUpdate={this.load}
                                    onEdit={this.onEdit}
                                    identifier={item.identifier}
                                    showSettings={true} />
                            </div>
                        )
                    }
                    <Dialog fullWidth maxWidth={"sm"} open={this.state.isRegisterModalOpen} onClose={(e) => this.setState({ isRegisterModalOpen: false })}>
                        <MuiDialogTitle className='text-center'>
                            {this.state.id ? `Editar câmera` : `Cadastrar câmera`}
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className="col-lg-12">
                                <label>Nome</label>
                                <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" required />
                            </div>
                            <div className='row'>
                                <div className="col-lg-6">
                                    <label>Tipo</label>
                                    <div className="input-group mb-3">
                                        <select className="form-select" onChange={(e) => this.setState({ type: e.target.value })} value={this.state.type} required>
                                            <option value='IN'>Entrada</option>
                                            <option value='OUT'>Saída</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label>Modelo</label>
                                    <div className="input-group mb-3">
                                        <select className="form-select" onChange={(e) => this.setState({ model: e.target.value })} value={this.state.model} required>
                                            <option value='Hikvision'>ZKTeco</option>
                                            <option value='Alphadigi'>Alphadigi</option>
                                            <option value='Dahua'>Dahua Smart ANPR</option>
                                            <option value='Intelbras' disabled>Intelbras</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.type === "IN" &&
                                <AlertWarning>
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="registerTicket" checked={this.state.registerTicket} onChange={(e) => this.setState({ registerTicket: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="registerTicket">
                                            Registrar entrada
                                        </label>
                                    </div>
                                    Assim que a câmera fizer a leitura da placa um ticket será registrado como Estacionado.
                                </AlertWarning>
                            }
                            {
                                this.state.type === "OUT" &&
                                <>
                                    <AlertWarning>
                                        <div className="form-check form-switch my-2">
                                            <input className="form-check-input" id="registerTicket" checked={this.state.registerTicket} onChange={(e) => this.setState({ registerTicket: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="registerTicket">
                                                Registrar saída
                                            </label>
                                            Assim que a câmera fizer a leitura da placa e o ticket estacionado ficará como Pago e Entregue caso não tenha pagamento pendente.
                                        </div>
                                        {/* <div className="form-check form-switch my-2">
                                            <input className="form-check-input" id="executePaymentWithTag" checked={this.state.executePaymentWithTag} onChange={(e) => this.setState({ executePaymentWithTag: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="executePaymentWithTag">
                                                Fazer cobrança via tag caso o ticket não esteja pago no momento da saída ( o veículo precisa ter uma tag habilitada ).
                                            </label>
                                        </div> */}
                                    </AlertWarning>
                                </>
                            }
                            <AlertInfo message={`Copie a URL abaixo e cole nas configurações de envio de imagem da câmera de LPR.`} />
                            <div className="col-lg-12">
                                <textarea className='form-control' rows={3} disabled>{this.state.urlSettings}</textarea>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                {
                                    this.state.id &&
                                    <button onClick={() => this.removeLPR()} className='btn btn-danger'>Apagar</button>
                                }
                                <button onClick={() => this.save()} className='btn btn-success'>{this.state.id ? `Atualizar` : `Cadastrar`}</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </div>
            </>
        )
    }
}

class CancelaCameraLPRPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            identifier: props.identifier,
            revendaId: getRevenda()?.id,
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            image: "",
            showSettings: props.showSettings,
            cameraLogs: [],
            state: "loading",
        }
    }

    componentDidMount = async () => {
        await this.setupReloadByTime(true)
    }

    setupReloadByTime = async () => {
        await this.load()
        setTimeout(() => {
            this.setupReloadByTime(false)
        }, 20000);
    }

    load = async () => {
        this.setState({ state: "loading" })
        var item = await getCamerasLPRByIdentifierAndParkId({ identifier: this.state.identifier, estacionamentoId: this.state.estacionamentoId })
        this.setState({ item: item })
        if (!isNullOrEmpty(item.base64)) {
            this.setState({ state: "fill-image" })
        } else {
            this.setState({ state: "fill" })
        }
    }

    removeLPR = async () => {
        var confirm = window.confirm(`Confirma mesmo a remoção deste equipamento?`);
        if (confirm) {
            const equipment = await getAllEquipmentsByLPRIdentifierAndParkId({
                lprIdentifier: this.state.item.identifier,
                estacionamentoId: this.state.estacionamentoId
            })
            if (equipment.length === 0) {
                await deleteCameraLPR({ id: this.state.id })
                this.setState({ isRegisterModalOpen: false })
                if (this.props.onUpdate) {
                    this.props.onUpdate()
                }
            } else {
                toastWarning(`Os equipamentos:\n\n${equipment.map(e => e.equipmentId)}\n\nEstão associados a esta câmera, você deve antes remover estas associações.`)
            }
        }
    }

    save = async () => {
        if (this.state.name === "") {
            toastWarning("Informe um nome para a câmera.")
            return
        }
        const data = {
            revendaId: this.state.revendaId,
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            name: this.state.name,
            model: this.state.model,
            type: this.state.type,
            identifier: this.state.identifier,
            urlSettings: this.state.urlSettings,
        }
        const user = getUsuarioCompact()
        const message = `Editou uma câmera de LPR.`
        await updateCameraLPR({ id: this.state.id, data: data })
        sendLogByUser("Câmera LPR", message, data)
        if (this.props.onUpdate) {
            this.props.onUpdate()
        }
    }

    analytics = async () => {
        toastLoading("Buscando veículos...")
        const identifier = this.state.item.identifier
        if (this.state.item.type === "IN") {
            const tickets = await ticketsByCameraInWithIdentifierAndParkId({ identifier: identifier, estacionamentoId: this.state.estacionamentoId })
            this.setState({ tickets: tickets })
        } else {
            const tickets = await ticketsByCameraOutWithIdentifierAndParkId({ identifier: identifier, estacionamentoId: this.state.estacionamentoId })
            this.setState({ tickets: tickets })
        }
        toastDismissLoading()
        this.setState({ isShowingAnalyticsModal: true })
    }

    showErrors = async () => {
        this.setState({ isShowingCameraErrorsModal: true })
    }

    showLogs = async () => {
        this.setState({ isShowingCameraLogsModal: true })
        this.setState({ isCameraLogsLoading: true })
        const logs = await getCamerasLPRLogsByIdentifierAndParkId({ id: this.state.item.id, estacionamentoId: this.state.estacionamentoId })
        this.setState({ cameraLogs: logs })
        this.setState({ isCameraLogsLoading: false })
    }

    onCloseAnalyticsDialog = () => {
        this.setState({ isShowingAnalyticsModal: false })
    }

    edit = async () => {
        if (this.props.onEdit) {
            this.props.onEdit(this.state.item)
        }
    }

    goToTicket = () => {
        const path = `/buscar/ticket?id=${this.state.item.currentTicket}`
        goToNewWindow(path)
    }

    removeWarnings = async () => {
        this.setState({ isShowingCameraErrorsModal: false })
        const item = this.state.item
        item.warnings = []
        await updateCameraLPR({ id: item.id, data: item })
        await this.load()
    }

    onRemove = async (item) => {
        var cameraLogs = this.state.cameraLogs
        cameraLogs = cameraLogs.filter(e => e.id !== item.id)
        this.setState({ cameraLogs: cameraLogs })
        await deleteCameraLogById({ id: item.id })
    }

    render() {
        return (
            <>
                <div className="card">
                    {
                        this.state.isLoading && <Loading />
                    }
                    {
                        this.state.item && <>
                            {
                                this.state.state === "loading" && <div style={{ height: "200px" }}>
                                    <Loading />
                                </div>
                            }
                            {
                                this.state.state === "fill-image" &&
                                <img style={{
                                    height: "200px",
                                    backgroundColor: "#c9c6c6",
                                    borderTopLeftRadius: 5,
                                    borderTopRightRadius: 5
                                }} src={`data:image/png;base64, ${this.state.item.base64}`} alt="Imagem veículo" />
                            }
                            {
                                this.state.state === "fill" &&
                                <div style={{ height: "200px", backgroundColor: "#c9c6c6", borderTopLeftRadius: 5, borderTopRightRadius: 5 }} />
                            }
                            <div className="card-body">
                                <h5 className="card-title">{this.state.item.name}</h5>
                                <small>{this.state.item.type === "IN" ? "Entrada - " : "Saída -"} {this.state.item.model == "Hikvision" ? "ZKTeco" : this.state.item.model}</small>
                            </div>
                            <div className="card-footer d-flex justify-content-between align-items-center">
                                {this.state.item.updatedAt ?
                                    <div className='row'>
                                        <div className='row'>
                                            <Tooltip title="Último registro" placement="top">
                                                <small className="text-muted">{Moment(this.state.item.updatedAt.seconds * 1000).format(`DD/MM/YYYY [às] HH:mm:ss`)}</small>
                                            </Tooltip>
                                        </div>
                                        <div className='row'>
                                            <small className="text-muted">Placa: {takeIfIsNotNullOrEmpty(this.state.item.plate) ?? "Não identificada"}</small>
                                        </div>
                                        <div className='row'>
                                            <small className="text-muted">Confidência:
                                                {
                                                    this.state.item.confidence ? <>
                                                        {
                                                            ((this.state.item.confidence ?? 0) >= 90) &&
                                                            <strong className='text-success'> {this.state.item.confidence ?? 0}%</strong>
                                                        }
                                                        {
                                                            ((this.state.item.confidence ?? 0) < 90) &&
                                                            <strong className='text-danger'> {this.state.item.confidence ?? 0}%</strong>
                                                        }
                                                    </> : <span>Não disponível</span>
                                                }
                                            </small>
                                        </div>
                                    </div> :
                                    <small className="text-muted">Nunca atualizado</small>
                                }
                                <div className='btn-group'>
                                    {
                                        this.state.item.currentTicket &&
                                        <Tooltip title="Visualizar ticket lançado" placement="top">
                                            <button className='btn btn-sm btn-success text-white' onClick={(e) => this.goToTicket()}>
                                                <i className="fas fa-ticket-alt" />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        this.state.showSettings &&
                                        <>
                                            {
                                                isAdministrador() && this.state.item.warnings && this.state.item.warnings?.length !== 0 &&
                                                <Tooltip title="Visualizar falhas de LPR" placement="top">
                                                    <button className='btn btn-sm btn-danger text-white' onClick={(e) => this.showErrors()}>
                                                        <i className="fas fa-exclamation-circle" />
                                                    </button>
                                                </Tooltip>
                                            }
                                            <Tooltip title="Histórico de imagens" placement="top">
                                                <button className='btn btn-sm btn-warning text-white' onClick={(e) => this.showLogs()}>
                                                    <i className="fas fa-boxes" />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Veículos lançados" placement="top">
                                                <button className='btn btn-sm btn-warning text-white' onClick={(e) => this.analytics()}>
                                                    <i className="fas fa-chart-line" />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Configurações" placement="top">
                                                <button className='btn btn-sm btn-secondary' onClick={(e) => this.edit()}>
                                                    <i className="fas fa-gear" />
                                                </button>
                                            </Tooltip>
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
                {
                    this.state.isShowingAnalyticsModal && <TicketAnalyticsDialog tickets={this.state.tickets} onClose={this.onCloseAnalyticsDialog} />
                }
                <Dialog fullWidth maxWidth={"md"} open={this.state.isShowingCameraLogsModal} onClose={(e) => this.setState({ isShowingCameraLogsModal: false })}>
                    {
                        this.state.isCameraLogsLoading ? <Loading /> : <>
                            {
                                this.state.cameraLogs.length === 0 ?
                                    <EmptyContent text='Nenhuma imagem recebida' /> :
                                    <>
                                        <MuiDialogTitle className='text-center'>
                                            <Tooltip title="Últimas 100 imagens" placement="top">
                                                <div>Imagens recebidas</div>
                                            </Tooltip>
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            <div className='col-lg-12'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td scope="col" width={10}></td>
                                                            <td scope="col"><strong>Data</strong></td>
                                                            <td scope="col"><strong>Confidência</strong></td>
                                                            <td scope="col"><strong>Placa</strong></td>
                                                            <td scope="col" width={10} align='right'></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.cameraLogs.map(item =>
                                                                <tr key={item.id}>
                                                                    <td>
                                                                        {
                                                                            !isNullOrEmpty(item.base64) &&
                                                                            <img style={{ borderRadius: 5, height: "200px", width: "200px", height: "200px" }} src={`data:image/png;base64, ${item.base64}`} width={200} height={200} alt="Imagem veículo" />
                                                                        }
                                                                    </td>
                                                                    <td>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                                    <td>{item.confidence}%</td>
                                                                    <td>{item.plate}</td>
                                                                    <td align='right'>
                                                                        <div className='btn-group'>
                                                                            {
                                                                                item.currentTicket &&
                                                                                <a href={`/buscar/ticket?id=${item.currentTicket}`} className='btn btn-primary'>
                                                                                    <i className="fas fa-ticket-alt" />
                                                                                </a>
                                                                            }
                                                                            <button onClick={() => this.onRemove(item)} className="btn btn-danger">
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                <button onClick={(e) => this.setState({ isShowingCameraLogsModal: false })} className='btn btn-secondary'>Fechar</button>
                                            </div>
                                        </MuiDialogActions>
                                    </>
                            }
                        </>
                    }
                </Dialog>
                <Dialog fullWidth maxWidth={"sm"} open={this.state.isShowingCameraErrorsModal} onClose={(e) => this.setState({ isShowingCameraErrorsModal: false })}>
                    <MuiDialogTitle className='text-center'>
                        Erros de identificação de placa
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='col-lg-12'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td scope="col"><strong>Mensagem</strong></td>
                                        <td scope="col"><strong>Data</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.item?.warnings?.map(item =>
                                            <tr key={item.id}>
                                                <td>{item.message}</td>
                                                <td>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.removeWarnings()} className='btn btn-danger'>Limpar mensagens</button>
                            <button onClick={(e) => this.setState({ isShowingCameraErrorsModal: false })} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

export { CancelaListarCamerasLPR, CancelaCameraLPRPreview }