import React from 'react';
import { NavBar } from '../../components/navbar'
import { getEstacionamento, getRevenda, isAdministrador, setUsuario } from '../../config/auth'
import { ManagerDashboard, TicketDashboard } from '../dashboard/dashboard-ticket'
import { getUserBySession, updateNotShowBannerDownloadedApp, updateNotShowBannerNFE, updateUserById } from '../../store/collections/userWorker';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getUsuario } from '../../config/auth';
import { goToNewWindow, isNull, isSelfParking, normalizedField, queryString, reloadWindow } from '../../shared/utils';
import { sendLogByUser, sendScreenView } from '../../shared/analytics';
import { getParameter, getParameterByParkId } from '../../store/collections/parameterWorker';
import { CashierExpiredComponent, CashierOpenComponent } from '../cashier/components';
import { activeCashier, verifyIfCashierIsExpired } from '../../store/collections/cashierWorker';
import { updateTicket } from '../../store/collections/ticketWorker';
import { Loading } from '../../components/common/commons';

class Home extends React.Component {

    constructor(props) {
        super(props)
        const user = getUsuario()
        this.state = {
            isLoading: true,
            state: "loading",
            caixaId: "",
            estacionamentoId: getEstacionamento()?.id,
            showDownloadedApp: false,
            notShowDownloadApp: false,
            showBannerMonthlyCharge: false,
            phone: user.cellphone,
            name: user.nome
        }
    }

    componentDidMount() {
        this.loadingCurrentCashier()
        this.loadCurrentUser()
        this.verifyDownloadApp()
        const from = queryString("from")
        if (from === "email") {
            sendScreenView("Menu", "Home | Por e-mail")
        }
    }

    loadCurrentUser = async () => {
        const currentUser = await getUserBySession()
        if (currentUser.isTermsUpdated) {
            window.location.href = "/conta/termos"
        }
    }

    openChargeWithNoCashier = async (item) => {
        const url = `/visualizar/mensalista/${item.monthlyId}?chargeId=${item.id}`
        goToNewWindow(url)

        const message = `Visualizau a cobrança ${item.id} sem caixa associada.`
        updateTicket("Home", message)
    }

    loadingCurrentCashier = async () => {
        if (this.state.estacionamentoId) {
            this.setState({ state: "loading" });
            const cashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
            if (cashier) {
                this.setState({ cashier: cashier })
                const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
                this.setState({ parameter: parameter })
                const isExpirated = await verifyIfCashierIsExpired({ cashier: cashier, estacionamentoId: this.state.estacionamentoId, parameter: parameter })
                if (!isExpirated) {
                    this.setState({ caixaId: cashier.id })
                    this.setState({ state: "open" })
                } else {
                    this.setState({ state: "expired" })
                }
            } else {
                this.setState({ state: "closed" })
            }
        }
    }

    applyFilter = () => {
        this.setState({ random: Math.random() })
    }

    verifyDownloadApp = () => {
        if (isSelfParking()) {
            var user = getUsuario()
            if (isAdministrador()) {
                const parameter = getParameter()
                if (isNull(parameter?.habilitarPagamentoOnlineMensalista)) {
                    if (isNull(user.showBannerMonthlyCharge)) {
                        this.setState({ showBannerMonthlyCharge: true })
                    }
                }
            }
            // if (user !== null && (user.notShowBannerDownloadedApp === undefined || user.notShowBannerDownloadedApp === false)) {
            //     this.setState({ showDownloadedApp: true })
            // } else {
            //     this.setState({ showDownloadedApp: false })
            // }
            // if (!parameter.habilitarEmissaoNotaV2) {
            //     if (user !== null && (user.notShowBannerNFE === undefined || user.notShowBannerNFE === false)) {
            //         this.setState({ showNFEBanner: true })
            //     } else {
            //         this.setState({ showNFEBanner: false })
            //     }
            // }
        }
    }

    closeDownloadApp = async () => {
        this.setState({ showDownloadedApp: false })
    }

    closeDownloadAppNotShowAnymore = async () => {
        var user = getUsuario()
        if (user !== null) {
            user.notShowBannerDownloadedApp = true
            setUsuario(user)
            await updateNotShowBannerDownloadedApp({ userId: user.id, notShowBannerDownloadedApp: true })
            reloadWindow()
        }
    }

    closeNFEAppNotShowAnymore = async () => {
        var user = getUsuario()
        if (user !== null) {
            this.setState({ showNFEBanner: false })

            user.notShowBannerNFE = true
            setUsuario(user)
            await updateNotShowBannerNFE({ userId: user.id, notShowBannerNFE: true })
            const message = `Tocou no banner de não ter interesse em habilitar emissão de notas.`
            sendLogByUser("Banner home", message)
        }
    }

    closeAndEnableNFE = async () => {
        var user = getUsuario()
        if (user !== null) {
            user.notShowBannerNFE = true
            setUsuario(user)
            await updateNotShowBannerNFE({ userId: user.id, notShowBannerNFE: true })

            const message = `Tocou no banner de habilitar emissão de notas.`
            sendLogByUser("Banner home", message)

            window.location.href = "/nota-fiscal/configurar"
        }
    }

    closeBannerMonthlyChargeNotShowAnymore = async () => {
        var user = getUsuario()
        if (user !== null) {
            this.setState({ showBannerMonthlyCharge: false })
            user.showBannerMonthlyCharge = true
            setUsuario(user)
            await updateUserById({ id: user.id, data: user })

            const message = `Tocou no banner de não ter interesse em habilitar cobrança de mensalista.`
            sendLogByUser("Banner home", message)
        }
    }

    closeBannerMonthlyChargeAndEnable = async () => {
        var user = getUsuario()
        if (user !== null) {
            this.setState({ showBannerMonthlyCharge: false })
            user.showBannerMonthlyCharge = true
            setUsuario(user)
            await updateUserById({ id: user.id, data: user })

            const message = `Tocou no banner de habilitar cobrança de mensalista.`
            sendLogByUser("Banner home", message)

            let revenda = getRevenda()
            const url = `https://api.whatsapp.com/send?phone=55${normalizedField(revenda.celular)}&message=Quero%20habilitar%20a%20cobrança%20dos%20mensalistas`
            goToNewWindow(url)
        }
    }

    render() {
        return (
            <>
                <NavBar>
                    {/* <Dialog maxWidth='sm' fullWidth open={this.state.showBannerMonthlyCharge}>
                        <MuiDialogContent>
                            <div className='text-center'>
                                <div className='my-4'>
                                    <img alt='Cobrança mensalista' style={{ width: "100%", height: "auto", borderRadius: "8px" }} src={require('../../files/marketing/cobranca_mensalista_quadrado.svg')} />
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="m-3">
                                <div className="btn-group">
                                    <button onClick={this.closeBannerMonthlyChargeNotShowAnymore} className='btn btn-secondary'>Não tenho interesse</button>
                                    <button onClick={this.closeBannerMonthlyChargeAndEnable} className='btn btn-success'>Falar com especialista</button>
                                </div>
                            </div>
                        </MuiDialogActions>
                    </Dialog> */}
                    <Dialog maxWidth='xs' fullWidth open={this.state.showNFEBanner}>
                        <MuiDialogContent>
                            <div className='text-center'>
                                <div className='my-4'>
                                    <h3>Mais facilidade no seu<br /> dia a dia.</h3>
                                    <span>Agora com a SelfParking você consegue <strong>emitir até 20 notas fiscais de serviço</strong> sem custo para pagamentos de tickets e mensalistsa com alguns cliques!</span> <p />
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="m-3">
                                <div className="btn-group">
                                    <button onClick={this.closeNFEAppNotShowAnymore} className='btn btn-secondary'>Não tenho interesse</button>
                                    <button onClick={this.closeAndEnableNFE} className='btn btn-success'>Habilitar emissão de notas</button>
                                </div>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog maxWidth='sm' open={this.state.showDownloadedApp}>
                        <MuiDialogContent>
                            <div className='text-center'>
                                <div className='my-4'>
                                    <h3>Bem vindo ao Self<strong>Parking</strong></h3>
                                    <span>Em breve entraremos em contato para te ajudar a configurar o estabelecimento do seu jeito.</span> <p />
                                    <span>Aproveite para baixar o nosso aplicativo.</span> <p />
                                    <div className='col-md-12 col-lg-12 col-sm-12'>
                                        <a href="https://play.google.com/store/apps/details?id=com.selfparking.app" rel="noopener noreferrer" target="_blank">
                                            <img src={require('../../files/animations/download_app.gif')} width={300} height={300} alt="Baixar aplicativo" />
                                            <img width='200' alt='Baixar aplicativo' src={require('../../files/icons/google-play-badge.png')} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="m-3">
                                <div className="btn-group">
                                    <button onClick={this.closeDownloadAppNotShowAnymore} className='btn btn-secondary'>Fechar e não mostrar novamente</button>
                                    <button onClick={this.closeDownloadApp} className='btn btn-success'>Fechar</button>
                                </div>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "open" && <div className='row'>
                            <div className='col-sm-2 col-lg-1 col-xs-2 mb-3'>
                                <ManagerDashboard />
                            </div>
                            <div className='col-sm-10 col-lg-11 col-xs-10 mb-3'>
                                <TicketDashboard
                                    key={this.state.random + 1}
                                    estacionamentoId={this.state.estacionamentoId}
                                    tickets={this.state.tickets}
                                    caixaId={this.state.caixaId} />
                            </div>
                        </div>
                    }
                    {
                        this.state.state === "closed" &&
                        <CashierOpenComponent />
                    }
                    {
                        this.state.state === "expired" &&
                        <CashierExpiredComponent
                            key={this.state.cashier?.id}
                            parameter={this.state.parameter}
                            cashier={this.state.cashier} />
                    }
                </NavBar >
            </>
        )
    }
}

export default Home;